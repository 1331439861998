import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from './components/Link';
import { getProducts } from './components/Product/Product';
import ProductGrid from './components/ProductGrid/ProductGrid';
import FilterChips from './components/FilterChips/FilterChips';
import constants from './constants/Constants';
import './App.css';

function App() {
  const [activeTabs, setActiveTabs] = useState<string[]>([]);
  const navigate = useNavigate();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#ffffff',
          },
          secondary: {
            main: '#e0e0e0',
          },
          background: {
            default: '#000000',
            paper: '#121212',
          },
        },
        typography: {
          fontFamily: 'Roboto, Arial, sans-serif',
          h2: {
            fontWeight: 700,
            letterSpacing: '-0.02em',
          },
          body1: {
            lineHeight: 1.8,
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: '#121212',
                borderRadius: '12px',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-6px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.3)',
                },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                backgroundColor: '#212121',
                color: '#e0e0e0',
                border: '1px solid #424242',
                borderRadius: '20px',
                margin: '4px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#303030',
                },
                '&.Mui-selected': {
                  backgroundColor: '#ffffff',
                  color: '#000000',
                },
              },
            },
          },
        },
      }),
    [],
  );

  const products = getProducts();
  const filteredProducts = products.filter(product =>
    activeTabs.length === 0 || activeTabs.includes(product.category)
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container maxWidth='lg' sx={{ textAlign: 'center', pt: 4, pb: 1 }}>
          <Typography variant='h2' component='h1' gutterBottom sx={{ color: '#ffffff', mb: 2 }}>
            Hi there!
          </Typography>
          <Typography variant='h4' gutterBottom sx={{ color: '#cccccc', mb: 1 }}>
            I'm Saarim, and I love building amazing things.
          </Typography>
          <Typography variant='body1' sx={{ color: '#ffffff', mb: -1 }}>
            Discover more about me on my{' '}
            <Link to='https://saarim.me'>
              homepage
            </Link>.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <FilterChips
              activeTabs={activeTabs}
              setActiveTabs={setActiveTabs}
              tabs={constants.TABS}
              tabLabels={constants.TAB_LABELS}
            />
          </Box>
          <Box sx={{ minHeight: '60vh' }}>
            <ProductGrid products={filteredProducts} />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;