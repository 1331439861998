import { default as DeleteIcon } from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import 'chart.js/auto';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';
import { DataGrid } from '@mui/x-data-grid';


interface SpotifyProps { }

const GREEN = '#00cc66';
const RED = '#990000';


const SpotifyCountArtists: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [artistTracks, setArtistTracks] = useState([]);
    const [playlistId, setPlaylistId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);

    useEffect(() => {
        document.title = 'Count Artists';
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const countArtistTracks = (playlistUrl: string) => {
        setIsLoading(true);
        setArtistTracks([]);
        Client_.client_().countArtistTracks(playlistUrl).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setArtistTracks(res?.result?.artist_counts);
                setPlaylistId(res?.result?.playlist_id);
            }
        });
    };

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleSpotifyUrl);
        countArtistTracks(constants.exampleSpotifyUrl);
    }

    const columns = [
        {
            field: 'artist',
            headerName: 'Artist',
            flex: 1,
            minWidth: 200,
            sortable: true,
            headerClassName: 'data-grid-header',
        },
        {
            field: 'count',
            headerName: 'Number of Songs',
            type: 'number',
            minWidth: 150,
            flex: 0.5,
            sortable: true,
            headerClassName: 'data-grid-header',
        },
    ];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Count Songs Per Artist in a Playlist
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '12px',
                maxWidth: '600px'
            }}>
                Enter the URL of the Spotify playlist to count the tracks per artist.
            </p>

            <p style={{
                color: '#b3b3b3',
                fontSize: '0.9rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist
            </p>

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />

            <div style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px'
            }}>
                {isLoading ? (
                    <CircularProgress sx={{ color: '#1DB954' }} />
                ) : (
                    <>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => countArtistTracks(playlistUrl)}
                        >
                            Count Tracks
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={setExampleData}
                        >
                            Use Example
                        </Button>
                    </>
                )}
            </div>

            {artistTracks?.length > 0 && (
                <Box sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mt: 3,
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    padding: '20px',
                    border: '1px solid #333',
                    '& .MuiDataGrid-root': {
                        border: 'none',
                        color: '#fff',
                        backgroundColor: '#282828',
                        borderRadius: '8px',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#1a1a1a',
                        borderBottom: '1px solid #404040',
                        color: '#1DB954',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: '1px solid #333',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: '#1a1a1a',
                        borderTop: '1px solid #404040',
                    },
                    '& .MuiTablePagination-root': {
                        color: '#b3b3b3',
                    },
                    '& .MuiButtonBase-root': {
                        color: '#b3b3b3',
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: '#333',
                    },
                }}>
                    <DataGrid
                        rows={artistTracks}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'count', sort: 'desc' }],
                            },
                        }}
                        pageSizeOptions={[10, 20, 50]}
                        disableRowSelectionOnClick
                        autoHeight
                    />
                </Box>
            )}
        </div>
    );
};

export default SpotifyCountArtists;
