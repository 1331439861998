import React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';

interface FilterChipsProps {
  activeTabs: string[];
  setActiveTabs: (tabs: string[]) => void;
  tabs: Record<string, string>;
  tabLabels: Record<string, string>;
  variant?: 'default' | 'compact';
}

const FilterChips: React.FC<FilterChipsProps> = ({
  activeTabs,
  setActiveTabs,
  tabs,
  tabLabels,
  variant = 'default'
}) => {
  const handleChipClick = (tab: string) => {
    setActiveTabs(activeTabs.includes(tab)
      ? activeTabs.filter((t: string) => t !== tab)
      : [...activeTabs, tab]
    );
  };

  const clearFilters = () => {
    setActiveTabs([]);
  };

  const styles = {
    default: {
      stack: {
        mt: 4,
        mb: 4,
        py: 2,
        px: 3,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        width: 'fit-content'
      },
      chip: {
        borderRadius: '50px',
        px: 1,
        height: '32px',
        fontSize: '0.95rem',
        fontWeight: 500,
      }
    },
    compact: {
      stack: {
        my: 2,
        py: 1,
        px: 2,
        borderRadius: 1,
        backgroundColor: 'transparent',
        width: 'fit-content'
      },
      chip: {
        borderRadius: '16px',
        height: '28px',
        fontSize: '0.85rem',
        fontWeight: 400,
      }
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={styles[variant].stack}
    >
      {activeTabs.length > 0 && (
        <Chip
          icon={<ClearIcon />}
          onClick={clearFilters}
          sx={{
            ...styles[variant].chip,
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            color: 'rgba(255, 255, 255, 0.9)',
            padding: 0,
            minWidth: '32px',
            width: '32px',
            height: '32px',
            '& .MuiChip-icon': {
              margin: 'auto',
              padding: 0,
              fontSize: '20px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.25)',
              boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
              transform: 'translateY(-1px)'
            },
          }}
        />
      )}

      {Object.entries(tabs).map(([key, value]) => (
        <Chip
          key={key}
          label={tabLabels[key as keyof typeof tabLabels]}
          onClick={() => handleChipClick(value)}
          sx={{
            ...styles[variant].chip,
            backgroundColor: activeTabs.includes(value)
              ? 'rgba(255, 255, 255, 0.9)'
              : 'rgba(255, 255, 255, 0.08)',
            color: activeTabs.includes(value)
              ? '#000000'
              : 'rgba(255, 255, 255, 0.9)',
            '&:hover': {
              backgroundColor: activeTabs.includes(value)
                ? 'rgba(255, 255, 255, 0.8)'
                : 'rgba(255, 255, 255, 0.15)',
              boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
            },
          }}
        />
      ))}
    </Stack>
  );
};

export default FilterChips;
