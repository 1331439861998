import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';
import constants from '../../constants/Constants';


interface FilteredArtistPlaylistProps { }

const exampleArtists = `AC SLATER
ANDRUSS
ANNICKA
AYYBO
BOYS NOIZE
CASSIAN
CHARLOTTE DE WITTE
CIREZ D
CLOONEE
DJ TENNIS
DOM DOLLA
DURANTE
B2B
Hana
ELDERBROOK
ELI BROWN
EMBRZ
ENRICO SANGIULIANO
ERIC PRYDZ
FATIMA HAJJI
FRANCIS MERCIER
GREEN VELVET
HEMINGUEY
J. WORRA
JOHN SUMMIT
JOSEPH RAY
JUHEUN
KASABLANCA
KASBO
LIVE
KASKADE
REDUX
LANE 8
B2B
SULTAN + SHEPARD
LAYTON GIORDANI
LE YOUTH
LUTTRELL
MANICS
LIVE
MARSH
MASSANE
MATRODA
MAU P
MICHELLE SPARKS
MINDCHATTER
MOON KYOO
OPPIDAN
OTHERWISH
PARISI
PATRICK TOPPING
RINZEN
ROMAIN GARCIA
SALUTE
SOFIA KOURTESIS
SWEET LIKE CHOCOLATE
VANDELUX
TA.
X-COAST`;

const examplePlaylistName = 'Proper NYE 2025';
const examplePlaylistUrl = 'https://open.spotify.com/playlist/1s0blQmhckG4PCkn5pyXZQ';

const FilteredArtistPlaylist: React.FC<FilteredArtistPlaylistProps> = () => {
    let navigate = useNavigate();
    const { addNotificationError } = useNotification();

    const [playlistUrl, setPlaylistUrl] = useState('');
    const [artists, setArtists] = useState('');
    const [playlistName, setPlaylistName] = useState('My Filtered Playlist');
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [stats, setStats] = useState<{
        total_tracks_found: number;
        matched_artists: string[];
        unmatched_artists: string[];
    } | null>(null);

    useEffect(() => {
        document.title = 'Create Filtered Artist Playlist';

        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };

        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const createFilteredPlaylist = (playlistUrl: string, artists: string, playlistName: string) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        setStats(null);

        Client_.client_().createFilteredArtistPlaylist(playlistUrl, artists, playlistName).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err);
                return;
            }

            setGeneratedPlaylistUrl(res?.result?.playlist_url);
            setStats({
                total_tracks_found: res?.result?.total_tracks_found,
                matched_artists: res?.result?.matched_artists,
                unmatched_artists: res?.result?.unmatched_artists
            });
        });
    }

    const setExampleData = () => {
        setArtists(exampleArtists);
        setPlaylistUrl(examplePlaylistUrl);
        setPlaylistName(examplePlaylistName);
        createFilteredPlaylist(examplePlaylistUrl, exampleArtists, examplePlaylistName);
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Filter Your Playlist
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Enter the artists you want to filter for (one per line) and the source playlist URL
            </p>

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="New Playlist Name (optional)"
                variant="outlined"
                value={playlistName}
                onChange={(event) => setPlaylistName(event.target.value)}
            />

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Artists (one per line)"
                variant="outlined"
                value={artists}
                multiline
                rows={10}
                onChange={(event) => setArtists(event.target.value)}
            />

            <div style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px'
            }}>
                {isLoading ? (
                    <CircularProgress sx={{ color: '#1DB954' }} />
                ) : (
                    <>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => createFilteredPlaylist(playlistUrl, artists, playlistName)}
                        >
                            Create Filtered Playlist
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={setExampleData}
                        >
                            Use Example
                        </Button>
                    </>
                )}
            </div>

            {generatedPlaylistUrl && (
                <div style={{
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <h2 style={{
                        color: '#1DB954',
                        marginBottom: '20px',
                        fontSize: '1.5rem'
                    }}>
                        Playlist Created
                    </h2>
                    <Link
                        to={generatedPlaylistUrl}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            backgroundColor: '#1DB954',
                            color: 'white',
                            borderRadius: '20px',
                            textDecoration: 'none',
                            marginBottom: '20px',
                            fontSize: '0.9rem',
                            transition: 'background-color 0.2s',
                            ':hover': {
                                backgroundColor: '#1ed760'
                            }
                        }}
                    >
                        Open in Spotify
                    </Link>
                </div>
            )}

            {stats && (
                <div style={{
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <div style={{
                        backgroundColor: '#1DB954',
                        color: 'white',
                        padding: '15px',
                        borderRadius: '8px',
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                        <h3 style={{
                            margin: 0,
                            fontSize: '1.2rem'
                        }}>
                            {stats.total_tracks_found} Tracks Found
                        </h3>
                    </div>

                    <div style={{
                        display: 'flex',
                        gap: '20px',
                        flexWrap: 'wrap'
                    }}>
                        <div style={{
                            flex: 1,
                            minWidth: '280px',
                            marginBottom: '20px'
                        }}>
                            <h4 style={{
                                color: '#1DB954',
                                borderBottom: '1px solid #1DB954',
                                paddingBottom: '8px',
                                fontSize: '1.1rem'
                            }}>
                                Matched Artists ({stats.matched_artists.length})
                            </h4>
                            <ul style={{
                                listStyle: 'none',
                                padding: '0',
                                margin: '0'
                            }}>
                                {stats.matched_artists.map((artist, idx) => (
                                    <li key={idx} style={{
                                        padding: '8px 12px',
                                        margin: '4px 0',
                                        backgroundColor: '#282828',
                                        borderRadius: '4px',
                                        fontSize: '0.9rem',
                                        color: '#fff'
                                    }}>
                                        {artist}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {stats.unmatched_artists.length > 0 && (
                            <div style={{
                                flex: 1,
                                minWidth: '280px',
                                marginBottom: '20px'
                            }}>
                                <h4 style={{
                                    color: '#b3b3b3',
                                    borderBottom: '1px solid #404040',
                                    paddingBottom: '8px',
                                    fontSize: '1.1rem'
                                }}>
                                    Not Found ({stats.unmatched_artists.length})
                                </h4>
                                <ul style={{
                                    listStyle: 'none',
                                    padding: '0',
                                    margin: '0'
                                }}>
                                    {stats.unmatched_artists.map((artist, idx) => (
                                        <li key={idx} style={{
                                            padding: '8px 12px',
                                            margin: '4px 0',
                                            backgroundColor: '#282828',
                                            borderRadius: '4px',
                                            fontSize: '0.9rem',
                                            color: '#b3b3b3'
                                        }}>
                                            {artist}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilteredArtistPlaylist;