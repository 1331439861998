
import React from "react";
import { getSpotifyProducts } from '../Product/Product';
import ProductGrid from '../ProductGrid/ProductGrid';

const SpotifyProducts: React.FC = () => {
    const products = getSpotifyProducts();
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <h1>Spotify Products</h1>
            <div style={{ width: '80%', maxWidth: '1200px', textAlign: 'center' }}>
                <ProductGrid products={products} />
            </div>
        </div>
    );
};


export default SpotifyProducts;
