import AddBoxIcon from '@mui/icons-material/AddBox';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { default as Delete, default as DeleteIcon } from '@mui/icons-material/Delete';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import 'chart.js/auto';
import { useEffect, useState, useRef } from 'react'; // Add useRef to imports
import { Bar } from 'react-chartjs-2';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { Theme as EmotionTheme } from '@emotion/react';
import { Theme as MuiTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormLabel from '@mui/material/FormLabel';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

declare module '@emotion/react' {
    export interface Theme extends MuiTheme { }
}

const UserAvatar = styled(Avatar)<{ selected: boolean }>(({ theme, selected }) => ({
    cursor: 'pointer',
    backgroundColor: selected ? theme.palette.primary.main : theme.palette.grey[300],
    color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
    '&:hover': {
        backgroundColor: selected ? theme.palette.primary.dark : theme.palette.grey[400],
    },
}));

const sampleData = {
    "items": [{ "id": 0, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 1, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 2, "item": "Loaded Nachos", "price": 20.95 }, { "id": 3, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 4, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 5, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 6, "item": "Subtotal", "price": 60.7 }, { "id": 7, "item": "Tax", "price": 5.31 }, { "id": 9, "item": "15% Tip TYPE IN TIP", "price": 9 }, { "id": 10, "item": "PAYMENT AMOUNT", "price": 75.01 }],
    "users": {
        "0": { "saarim": 1 },
        "1": { "saarim": 1 },
        "2": { "saarim": 1, "victor": 1, "riley": 1 },
        "3": { "riley": 1 },
        "4": { "riley": 1 },
        "5": { "riley": 1 }
    },
    "ids": { "6": "Subtotal", "7": "Tax", "9": "Tip", "10": "Total" },
    "people": ['saarim', 'victor', 'riley']
}

const AVATAR_COLORS = [
    '#00b4d8', // bright blue
    '#ff595e', // coral red
    '#8ac926', // lime green
    '#9b5de5', // purple
    '#f15bb5', // pink
    '#fee440', // yellow
    '#00f5d4', // turquoise
    '#ff9e00', // orange
    '#4cc9f0', // light blue
    '#80ed99', // mint green
];

const getUserColor = (username: string, allUsers: string[]) => {
    // Find the index of this username in the full users array
    const userIndex = allUsers.indexOf(username);
    // Use modulo to wrap around if we have more users than colors
    return AVATAR_COLORS[userIndex % AVATAR_COLORS.length];
};

interface UserWeight {
    [user: string]: number;
}

interface UserWeights {
    [itemId: number]: UserWeight;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(2),
        minWidth: '300px',
        maxWidth: '500px',
        width: '100%',
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        boxShadow: theme.shadows[24],
        border: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            margin: theme.spacing(1),
            maxHeight: '90vh',
            overflowY: 'auto',
        }
    },
    '& .MuiDialogContent-root': {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            paddingTop: `${theme.spacing(1)} !important`,
        }
    }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    color: theme.palette.primary.main,
    fontWeight: 600
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
        color: theme.palette.text.secondary
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.divider
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main
        }
    }
}));

const WeightContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    }
}));

const UserWeightRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&:last-child': {
        borderBottom: 'none'
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        padding: theme.spacing(1.5, 0),
        '& .MuiTypography-root': {
            fontSize: '0.9rem',
        }
    }
}));

// First, let's add proper interfaces for our item
interface EditingItem {
    id: number;
    item: string;
    price: number | string;
    purchased_by: string[];
    itemType?: string;
}

const BillSplitter: React.FC = () => {
    const [file, setFile] = useState<any>(null);
    const [items, setItems] = useState<any>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<UserWeights>({});
    const [selectedItemTypes, setSelectedItemTypes] = useState<{ [itemId: number]: string }>({});
    const [imageSrc, setImageSrc] = useState<any>(null);

    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [users, setUsers] = useState<string[]>([]);
    const [billSplit, setBillSplit] = useState<any>(null);
    const [selectedItemId, setSelectedItemId] = useState<any>(null);
    const [numPeriods, setNumPeriods] = useState(1);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<EditingItem | null>(null);
    const billSummaryRef = useRef<HTMLDivElement>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [customTotal, setCustomTotal] = useState<string>('');



    const itemTypes = ['Tax', 'Tip', 'Subtotal', 'Total', 'Item']

    const renderImageModal = () => (
        <Dialog
            open={isImageModalOpen}
            onClose={() => setIsImageModalOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Uploaded Receipt</DialogTitle>
            <DialogContent>
                <img
                    src={imageSrc}
                    alt="uploaded receipt"
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsImageModalOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );

    useEffect(() => {
        document.title = 'Split Bills';
    }, []);

    const useSampleData = () => {
        handleClear();
        setItems(sampleData.items)
        setSelectedUsers(sampleData.users)
        setSelectedItemTypes(sampleData.ids)
        setUsers(sampleData.people)
    }

    const handleAddUser = (event: any) => {
        const newUser = event.target.value.trim();
        if (newUser !== '' && !users.includes(newUser)) {
            setUsers([...users, newUser]);
            event.target.value = '';
        } else if (users.includes(newUser)) {
            event.target.value = '';
        }
    };

    const handleDeleteUser = (userToDelete: string) => {
        // Also need to clean up billSplit if it exists
        if (billSplit) {
            setBillSplit(null);  // Reset bill split when users change
        }
        // Remove user from users array
        const updatedUsers = users.filter((user) => user !== userToDelete);
        setUsers(updatedUsers);

        // Remove user from all items' selected users
        const updatedSelectedUsers = { ...selectedUsers };
        Object.keys(updatedSelectedUsers).forEach((itemId) => {
            const newWeights: UserWeight = { ...updatedSelectedUsers[Number(itemId)] };
            delete newWeights[userToDelete];
            updatedSelectedUsers[Number(itemId)] = newWeights;
        });
        setSelectedUsers(updatedSelectedUsers);

        // If the user is in the editing modal, remove them there too
        if (editingItem && editingItem.purchased_by) {
            setEditingItem({
                ...editingItem,
                purchased_by: editingItem.purchased_by.filter((user: string) => user !== userToDelete)
            });
        }
    };

    const handleFileChange = (event: any) => {
        handleClear();
        setFile(event.target.files[0]);
        setImageSrc(URL.createObjectURL(event.target.files[0]));
    };

    const handleFileUpload = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('img', file);
        Client_.client_().getItemsFromReceipt(formData).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                handleClear();
                setItems(res?.result?.items)
                setSelectedItemTypes(res?.result?.item_types)
            }
        });
    };

    // Add this useEffect to handle scrolling when billSplit changes
    useEffect(() => {
        if (billSplit && billSummaryRef.current) {
            billSummaryRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [billSplit]);

    const splitBill = async () => {
        console.log(selectedUsers);
        setIsLoading(true);
        Client_.client_().splitBill(
            items,
            selectedUsers,
            selectedItemTypes,
            customTotal ? Number(customTotal) : null
        ).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setBillSplit(res?.result)
            }
        });
    };

    const handleClear = () => {
        setFile(null);
        setItems([]);
        setBillSplit(null);
        setSelectedUsers({});
        setSelectedItemTypes({});
        setImageSrc(null);
        setUsers([]);
        setEditingItem(null);
        setIsModalOpen(false);
        setSelectedItemId(null);
    };

    const handleOpenModal = (item?: any) => {
        if (item) {
            setEditingItem({
                id: item.id,
                item: item.item,
                price: item.price,
                purchased_by: Object.keys(selectedUsers[item.id] || {})
            });
        } else {
            const newId = items.reduce((maxId: number, item: any) => Math.max(maxId, item.id), -1) + 1;
            setEditingItem({
                id: newId,
                item: '',
                price: '',
                purchased_by: []
            });
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingItem(null);
    };

    const handleSaveItem = () => {
        if (!editingItem) {
            addNotificationError('No item is being edited');
            return;
        }

        if (isNaN(Number(editingItem.price)) || Number(editingItem.price) <= 0) {
            addNotificationError('Please enter a valid price greater than 0');
            return;
        }

        // Format price to always have 2 decimal places
        const formattedPrice = Number(Number(editingItem.price).toFixed(2));
        const updatedItem = {
            id: editingItem.id,
            item: editingItem.item,
            price: formattedPrice
        };

        // Create weights object for selected users
        const userWeights: UserWeight = {};
        editingItem.purchased_by.forEach((user: string) => {
            const currentWeight = selectedUsers[editingItem.id]?.[user] || 1;
            userWeights[user] = currentWeight;
        });

        // Reset bill split since items are changing
        setBillSplit(null);

        // Update items array
        const itemExists = items.some((item: any) => item.id === editingItem.id);
        if (itemExists) {
            setItems(items.map((item: any) =>
                item.id === editingItem.id ? updatedItem : item
            ));
        } else {
            setItems([...items, updatedItem]);
        }

        // Update selected users
        const updatedSelectedUsers: UserWeights = {
            ...selectedUsers,
            [editingItem.id]: userWeights
        };
        setSelectedUsers(updatedSelectedUsers);

        handleCloseModal();
    };

    const handleAddRow = () => {
        handleOpenModal();
    };

    const handleDeleteRow = (itemId: any) => {
        // Reset bill split since items are changing
        setBillSplit(null);

        setItems((prevItems: any) => prevItems.filter((prevItem: any) => prevItem.id !== itemId));
        setSelectedItemTypes((prev) => {
            const updated = { ...prev };
            delete updated[itemId];
            return updated;
        });
        setSelectedUsers((prev) => {
            const updated = { ...prev };
            delete updated[itemId];
            return updated;
        });
    };

    const getInitials = (name: string) => {
        return name.split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    const renderSpecialItemsSelector = () => (
        <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
                Identify Special Items
            </Typography>
            <Grid container spacing={2}>
                {['Subtotal', 'Tax', 'Tip', 'Total'].map((type) => {
                    const selectedItemId = Object.entries(selectedItemTypes)
                        .find(([_, value]) => value === type)?.[0];
                    const selectedItem = items.find((item: any) => item.id.toString() === selectedItemId);

                    return (
                        <Grid item xs={12} sm={6} md={3} key={type}>
                            <Card
                                variant="outlined"
                                sx={{
                                    bgcolor: 'background.paper',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        boxShadow: 1
                                    }
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        color="primary"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 500,
                                            mb: 1.5
                                        }}
                                    >
                                        {type}
                                    </Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={selectedItemId || ''}
                                            onChange={(e: any) => {
                                                const newItemTypes = { ...selectedItemTypes };
                                                // Remove old assignment if exists
                                                Object.entries(selectedItemTypes).forEach(([key, value]: any) => {
                                                    if (value === type) delete newItemTypes[key];
                                                });
                                                // Add new assignment if an item is selected
                                                if (e.target.value) {
                                                    newItemTypes[e.target.value] = type;
                                                }
                                                setSelectedItemTypes(newItemTypes);
                                            }}
                                            displayEmpty
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'divider'
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Select {type} Item</em>
                                            </MenuItem>
                                            {items.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.item} (${Number(item.price).toFixed(2)})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );

    const sortedItems = () => {
        return items.filter((item: any) => !selectedItemTypes[item.id]);
    };

    const renderItemsList = () => (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
                Assign People to Items
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="center">People</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedItems().map((item: any) => (
                            <TableRow key={item.id}>
                                <TableCell
                                    onClick={() => handleOpenModal(item)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                                >
                                    {item.item}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    onClick={() => handleOpenModal(item)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                                >
                                    ${Number(item.price).toFixed(2)}
                                </TableCell>
                                <TableCell align="center" sx={{ minWidth: '150px' }}>
                                    <Select
                                        multiple
                                        size="small"
                                        value={selectedUsers[item.id] ? Object.keys(selectedUsers[item.id]) : []}
                                        onChange={(e) => {
                                            const value = e.target.value as string[];
                                            // Check if the special "select-all" value is present
                                            if (value.includes('select-all')) {
                                                // If all users are already selected, deselect all
                                                // Otherwise, select all
                                                const currentUsers = selectedUsers[item.id] || {};
                                                const newWeights: UserWeight = {};

                                                if (Object.keys(currentUsers).length === users.length) {
                                                    // Deselect all - empty object
                                                } else {
                                                    // Select all - set weight of 1 for each user
                                                    users.forEach(user => {
                                                        newWeights[user] = 1;
                                                    });
                                                }

                                                setSelectedUsers({
                                                    ...selectedUsers,
                                                    [item.id]: newWeights
                                                });
                                            } else {
                                                const newWeights: UserWeight = {};
                                                value.forEach(user => {
                                                    // Preserve existing weights or set to 1 for new users
                                                    newWeights[user] = (selectedUsers[item.id]?.[user] || 1);
                                                });

                                                setSelectedUsers({
                                                    ...selectedUsers,
                                                    [item.id]: newWeights
                                                });
                                            }
                                        }}
                                        renderValue={(selected) => (
                                            <AvatarGroup
                                                max={4}
                                                sx={{
                                                    '& .MuiAvatar-root': {
                                                        width: 24,
                                                        height: 24,
                                                        fontSize: '0.75rem'
                                                    }
                                                }}
                                            >
                                                {(selected as string[]).filter(Boolean).map((user) => (
                                                    <Avatar
                                                        key={user}
                                                        sx={{
                                                            bgcolor: getUserColor(user, users),
                                                            color: '#ffffff'
                                                        }}
                                                    >
                                                        {user ? getInitials(user) : ''}
                                                    </Avatar>
                                                ))}
                                            </AvatarGroup>
                                        )}
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="select-all">
                                            {(selectedUsers[item.id]?.length || 0) === users.length ? 'Deselect All' : 'Select All'}
                                        </MenuItem>
                                        <Divider />
                                        {users.map((user) => (
                                            <MenuItem key={user} value={user}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Avatar
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                            fontSize: '0.75rem',
                                                            bgcolor: getUserColor(user, users),
                                                            color: '#ffffff'
                                                        }}
                                                    >
                                                        {getInitials(user)}
                                                    </Avatar>
                                                    {user}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    startIcon={<AddBoxIcon />}
                    onClick={handleAddRow}
                >
                    Add Item
                </Button>
            </Box>
        </Box>
    );

    const handleUserSelection = (itemId: number, selectedUsersList: string[]) => {
        const newWeights: UserWeight = {};
        selectedUsersList.forEach(user => {
            newWeights[user] = 1; // Default weight is 1
        });

        setSelectedUsers({
            ...selectedUsers,
            [itemId]: newWeights
        });
    };

    const handleUpdateWeight = (itemId: number, user: string, newWeight: number) => {
        setSelectedUsers({
            ...selectedUsers,
            [itemId]: {
                ...selectedUsers[itemId],
                [user]: newWeight
            }
        });
    };

    const handleUserSelectionInModal = (selectedUsersList: string[]) => {
        if (!editingItem) return;

        setEditingItem({
            ...editingItem,
            purchased_by: selectedUsersList
        });

        // Initialize weights for selected users
        const newWeights: UserWeight = {};
        selectedUsersList.forEach(user => {
            // Get existing weight or default to 1
            const existingWeight = selectedUsers[editingItem.id]?.[user];
            newWeights[user] = existingWeight || 1;
        });

        const updatedSelectedUsers: UserWeights = {
            ...selectedUsers,
            [editingItem.id]: newWeights
        };

        setSelectedUsers(updatedSelectedUsers);
    };

    const renderEditModal = () => (
        <StyledDialog
            open={!!editingItem}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="sm"
        >
            {editingItem && (
                <>
                    <StyledDialogTitle>
                        {!items.some((item: any) => item.id === editingItem.id) ? 'Add Item' : 'Edit Item'}
                        <IconButton
                            onClick={handleCloseModal}
                            size="small"
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'primary.main',
                                    backgroundColor: 'rgba(144, 202, 249, 0.08)'
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </StyledDialogTitle>
                    <DialogContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: { xs: 1.5, sm: 2 }
                        }}>
                            <StyledFormControl>
                                <FormLabel>Item Type</FormLabel>
                                <Select
                                    value={editingItem.itemType || 'Item'}
                                    onChange={(e) => {
                                        const newType = e.target.value;
                                        setEditingItem({ ...editingItem, itemType: newType });

                                        // Update selectedItemTypes
                                        const updatedTypes = { ...selectedItemTypes };

                                        // Remove any existing assignments for this type
                                        Object.entries(selectedItemTypes).forEach(([key, value]: any) => {
                                            if (value === newType) {
                                                delete updatedTypes[key];
                                            }
                                        });

                                        // Add new type assignment if it's not a regular item
                                        if (newType !== 'Item') {
                                            updatedTypes[editingItem.id] = newType;
                                        } else {
                                            delete updatedTypes[editingItem.id];
                                        }

                                        setSelectedItemTypes(updatedTypes);
                                    }}
                                >
                                    {itemTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledFormControl>

                            <StyledFormControl>
                                <TextField
                                    label="Item Name"
                                    value={editingItem.item}
                                    onChange={(e) => setEditingItem({ ...editingItem, item: e.target.value })}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'primary.main'
                                            }
                                        }
                                    }}
                                />
                            </StyledFormControl>

                            <StyledFormControl>
                                <TextField
                                    label="Price"
                                    type="number"
                                    value={editingItem.price}
                                    onChange={(e) => setEditingItem({ ...editingItem, price: parseFloat(e.target.value) || 0 })}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'primary.main'
                                            }
                                        }
                                    }}
                                />
                            </StyledFormControl>

                            {(!editingItem.itemType || editingItem.itemType === 'Item') && (
                                <>
                                    <StyledFormControl>
                                        <FormLabel
                                            component="legend"
                                            sx={{
                                                marginBottom: 1,
                                                color: 'primary.main',
                                                '&.Mui-focused': {
                                                    color: 'primary.main'
                                                }
                                            }}
                                        >
                                            Purchased By
                                        </FormLabel>
                                        <Select
                                            multiple
                                            value={editingItem.purchased_by}
                                            onChange={(e) => {
                                                const value = e.target.value as string[];
                                                if (value.includes('select-all')) {
                                                    if (editingItem.purchased_by.length === users.length) {
                                                        handleUserSelectionInModal([]);
                                                    } else {
                                                        handleUserSelectionInModal([...users]);
                                                    }
                                                } else {
                                                    handleUserSelectionInModal(value);
                                                }
                                            }}
                                            fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'divider'
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                }
                                            }}
                                            renderValue={(selected) => (
                                                <AvatarGroup
                                                    max={4}
                                                    sx={{
                                                        '& .MuiAvatar-root': {
                                                            width: 24,
                                                            height: 24,
                                                            fontSize: '0.75rem'
                                                        }
                                                    }}
                                                >
                                                    {(selected as string[]).map((user) => (
                                                        <Avatar
                                                            key={user}
                                                            sx={{
                                                                bgcolor: getUserColor(user, users),
                                                                color: '#ffffff'
                                                            }}
                                                        >
                                                            {getInitials(user)}
                                                        </Avatar>
                                                    ))}
                                                </AvatarGroup>
                                            )}
                                        >
                                            <MenuItem value="select-all">
                                                <ListItemText
                                                    primary={editingItem.purchased_by.length === users.length ? 'Deselect All' : 'Select All'}
                                                />
                                            </MenuItem>
                                            <Divider />
                                            {users.map((user) => (
                                                <MenuItem key={user} value={user}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                                                        <Checkbox
                                                            checked={editingItem.purchased_by.includes(user)}
                                                        />
                                                        <Avatar
                                                            sx={{
                                                                width: 24,
                                                                height: 24,
                                                                fontSize: '0.75rem',
                                                                bgcolor: getUserColor(user, users),
                                                                color: '#ffffff'
                                                            }}
                                                        >
                                                            {getInitials(user)}
                                                        </Avatar>
                                                        <ListItemText primary={user} />
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledFormControl>

                                    <Accordion
                                        sx={{
                                            mt: { xs: 1.5, sm: 2 },
                                            '& .MuiAccordionSummary-root': {
                                                minHeight: { xs: 40, sm: 48 },
                                                px: { xs: 1, sm: 2 },
                                            },
                                            '& .MuiAccordionDetails-root': {
                                                padding: 2,
                                                px: { xs: 1, sm: 2 },
                                                py: { xs: 1, sm: 1.5 },
                                                borderTop: '1px solid',
                                                borderTopColor: 'divider'
                                            },
                                            '&.MuiAccordion-root': {
                                                boxShadow: 'none',
                                                border: '1px solid',
                                                borderColor: 'divider',
                                                borderRadius: 1,
                                                '&:before': {
                                                    display: 'none',
                                                },
                                                '&.Mui-expanded': {
                                                    margin: 0,
                                                    mt: 2
                                                }
                                            },
                                            '& .MuiAccordionSummary-content': {
                                                margin: '12px 0',
                                                '&.Mui-expanded': {
                                                    margin: '12px 0',
                                                }
                                            }
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                                            aria-controls="split-between-content"
                                            id="split-between-header"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    color: 'primary.main',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1
                                                }}
                                            >
                                                Advanced: Custom Split Ratios
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'block',
                                                    mb: { xs: 1.5, sm: 2 },
                                                    p: { xs: 1, sm: 1.5 },
                                                    fontSize: { xs: '0.7rem', sm: '0.75rem' },
                                                    backgroundColor: 'rgba(144, 202, 249, 0.08)',
                                                    color: 'text.secondary'
                                                }}
                                            >
                                                Adjust portions for each person. For example, if someone had a double portion, set their multiplier to 2x.
                                            </Typography>
                                            <Box sx={{
                                                maxHeight: { xs: '200px', sm: 'none' },
                                                overflowY: 'auto',
                                                pr: 1
                                            }}>
                                                {editingItem.purchased_by.map((user: string) => (
                                                    <UserWeightRow key={user}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            width: { xs: '100%', sm: 'auto' }
                                                        }}>
                                                            <Avatar
                                                                sx={{
                                                                    width: { xs: 28, sm: 24 },
                                                                    height: { xs: 28, sm: 24 },
                                                                    fontSize: { xs: '0.8rem', sm: '0.75rem' },
                                                                    bgcolor: getUserColor(user, users),
                                                                }}
                                                            >
                                                                {getInitials(user)}
                                                            </Avatar>
                                                            <Typography>{user}</Typography>
                                                        </Box>
                                                        <WeightContainer>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    const currentWeight = selectedUsers[editingItem.id]?.[user] || 1;
                                                                    handleUpdateWeight(editingItem.id, user, Math.max(1, currentWeight - 1));
                                                                }}
                                                                sx={{ color: 'primary.main' }}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                            <Typography
                                                                sx={{
                                                                    minWidth: '40px',
                                                                    textAlign: 'center',
                                                                    fontWeight: 'medium'
                                                                }}
                                                            >
                                                                {selectedUsers[editingItem.id]?.[user] || 1}x
                                                            </Typography>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    const currentWeight = selectedUsers[editingItem.id]?.[user] || 1;
                                                                    handleUpdateWeight(editingItem.id, user, currentWeight + 1);
                                                                }}
                                                                sx={{ color: 'primary.main' }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </WeightContainer>
                                                    </UserWeightRow>
                                                ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{
                        px: { xs: 0, sm: 0 },
                        pb: { xs: 0, sm: 0 },
                        mt: { xs: 1, sm: 2 },
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            {items.some((item: any) => item.id === editingItem.id) && (
                                <Button
                                    onClick={() => {
                                        handleDeleteRow(editingItem.id);
                                        handleCloseModal();
                                    }}
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                >
                                    Delete
                                </Button>
                            )}
                        </Box>
                        <Box>
                            <Button
                                onClick={handleCloseModal}
                                sx={{
                                    color: 'text.secondary',
                                    '&:hover': {
                                        backgroundColor: 'rgba(144, 202, 249, 0.08)'
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSaveItem}
                                variant="contained"
                                sx={{
                                    ml: 1,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            )}
        </StyledDialog>
    );

    const renderCustomTotalInput = () => (
        <Accordion
            sx={{
                mt: 2,
                '& .MuiAccordionSummary-root': {
                    minHeight: { xs: 40, sm: 48 },
                    px: { xs: 1, sm: 2 },
                },
                '& .MuiAccordionDetails-root': {
                    padding: 2,
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 },
                    borderTop: '1px solid',
                    borderTopColor: 'divider'
                },
                '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    '&:before': {
                        display: 'none',
                    },
                    '&.Mui-expanded': {
                        margin: 0,
                        mt: 2
                    }
                },
                '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                    '&.Mui-expanded': {
                        margin: '12px 0',
                    }
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                aria-controls="currency-conversion-content"
                id="currency-conversion-header"
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    Advanced: Currency Conversion
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    variant="caption"
                    sx={{
                        display: 'block',
                        mb: { xs: 1.5, sm: 2 },
                        p: { xs: 1, sm: 1.5 },
                        fontSize: { xs: '0.7rem', sm: '0.75rem' },
                        backgroundColor: 'rgba(144, 202, 249, 0.08)',
                        color: 'text.secondary',
                        borderRadius: 1
                    }}
                >
                    When splitting a foreign purchase (e.g., €100 on receipt, $108 on bank statement), enter the dollar amount ($108) for accurate splitting.
                </Typography>
                <TextField
                    label="Custom Total Amount"
                    value={customTotal}
                    onChange={(e) => setCustomTotal(e.target.value)}
                    type="number"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    sx={{
                        minWidth: { xs: '100%', sm: '300px' },
                        '& .MuiOutlinedInput-root': {
                            padding: '3px 14px',
                            '& fieldset': {
                                borderColor: 'divider'
                            },
                            '&:hover fieldset': {
                                borderColor: 'primary.main'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main'
                            }
                        }
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );

    const renderBillSummary = () => (
        <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={6} sm={3}>
                <Card sx={{ bgcolor: 'primary.light', color: 'primary.contrastText' }}>
                    <CardContent sx={{ py: 1 }}>
                        <Typography variant="body2">Subtotal</Typography>
                        <Typography variant="h6">${billSplit.subtotal.toFixed(2)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Card sx={{ bgcolor: 'secondary.light', color: 'secondary.contrastText' }}>
                    <CardContent sx={{ py: 1 }}>
                        <Typography variant="body2">Tax</Typography>
                        <Typography variant="h6">${billSplit.tax.toFixed(2)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Card sx={{ bgcolor: 'info.light', color: 'info.contrastText' }}>
                    <CardContent sx={{ py: 1 }}>
                        <Typography variant="body2">Tip</Typography>
                        <Typography variant="h6">${billSplit.tip.toFixed(2)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Card sx={{ bgcolor: 'success.light', color: 'success.contrastText' }}>
                    <CardContent sx={{ py: 1 }}>
                        <Typography variant="body2">Total</Typography>
                        <Typography variant="h6">${billSplit.total.toFixed(2)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            {billSplit.conversion_ratio && (
                <Grid item xs={6} sm={3}>
                    <Card sx={{ bgcolor: 'warning.light', color: 'warning.contrastText' }}>
                        <CardContent sx={{ py: 1 }}>
                            <Typography variant="body2">Conversion Ratio</Typography>
                            <Typography variant="h6">{billSplit.conversion_ratio.toFixed(4)}x</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    );

    return (
        <Paper sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography variant="h4" align="center" sx={{ mb: 1 }}>Bill Splitter</Typography>
            <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                Upload a receipt to extract items and quickly split the bill with your friends!
            </Typography>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                alignItems="stretch"
                sx={{ '& .MuiButton-root': { minHeight: '36px' } }} // Ensures consistent button height
            >
                <label htmlFor="file-upload-button" style={{ flex: 1 }}>
                    <input
                        id="file-upload-button"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <Tooltip title="Upload a receipt">
                        <Button
                            component="span"
                            variant="contained"
                            startIcon={<CloudUpload />}
                            fullWidth
                        >
                            Upload
                        </Button>
                    </Tooltip>
                </label>

                <Button
                    variant="contained"
                    startIcon={<Delete />}
                    onClick={handleClear}
                    sx={{ flex: 1 }}
                >
                    Clear
                </Button>

                <Button
                    variant='contained'
                    onClick={useSampleData}
                    startIcon={<LibraryBooksIcon />}
                    sx={{ flex: 1 }}
                >
                    Use Sample Data
                </Button>

                <Box sx={{ flex: 1, display: 'flex' }}>
                    {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleFileUpload}
                            disabled={!file}
                            startIcon={<ImageSearchIcon />}
                            fullWidth
                        >
                            Extract Items
                        </Button>
                    )}
                </Box>
            </Stack>


            {imageSrc && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button
                        variant="outlined"
                        onClick={() => setIsImageModalOpen(true)}
                        startIcon={<ImageSearchIcon />}
                    >
                        View Uploaded Receipt
                    </Button>
                </Box>
            )}
            <Box sx={{ mt: 2 }}>
                <TextField
                    label='Add people to split with'
                    variant='outlined'
                    helperText='Press enter after each name'
                    onKeyUp={(e) => e.key === 'Enter' && handleAddUser(e)}
                    fullWidth
                    sx={{
                        mb: 1,
                        minWidth: { xs: '100%', sm: '400px' },
                        '& .MuiOutlinedInput-root': {
                            padding: '3px 14px'  // Standard padding
                        }
                    }}
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                    {users.map((user) => (
                        <Chip
                            key={user}
                            label={user}
                            onDelete={() => handleDeleteUser(user)}
                            color='primary'
                            variant='outlined'
                        />
                    ))}
                </Box>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    startIcon={<AddBoxIcon />}
                    onClick={handleAddRow}
                >
                    Add Item
                </Button>
            </Box>

            {items?.length > 0 && (
                <>
                    {renderSpecialItemsSelector()}
                    {renderItemsList()}
                    {renderCustomTotalInput()}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title="Split the bill" placement="top">
                            <Button
                                variant="contained"
                                startIcon={<PriceChangeIcon />}
                                onClick={splitBill}
                                sx={{ m: 2 }}
                            >
                                Split
                            </Button>
                        </Tooltip>
                    </Box>
                </>
            )}

            {billSplit && (
                <Box sx={{ mt: 3 }} ref={billSummaryRef}>
                    <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                        Bill Summary
                    </Typography>
                    {renderBillSummary()}
                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                        Individual Breakdowns
                    </Typography>
                    <Grid container spacing={2}>
                        {billSplit.all_users.map((userId: string) => (
                            <Grid item xs={12} sm={6} md={4} key={userId}>
                                <Card
                                    elevation={3}
                                    sx={{
                                        height: '100%',
                                        transition: 'transform 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                        }
                                    }}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Avatar
                                                sx={{
                                                    bgcolor: getUserColor(userId, billSplit.all_users),
                                                    width: 48,
                                                    height: 48,
                                                    mr: 2,
                                                    color: '#ffffff'
                                                }}
                                            >
                                                {userId.charAt(0).toUpperCase()}
                                            </Avatar>
                                            <Typography variant="h6">{userId}</Typography>
                                        </Box>

                                        <Stack spacing={2}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Pre Tax & Tip
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        ${billSplit.costs[userId].toFixed(2)}
                                                    </Typography>
                                                </Box>

                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Share of Total
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {(billSplit.proportions[userId] * 100).toFixed(2)}%
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                bgcolor: 'primary.dark',
                                                p: 2,
                                                borderRadius: 1,
                                                textAlign: 'center',
                                                color: 'white',
                                                boxShadow: 2
                                            }}>
                                                <Typography variant="subtitle2" sx={{ opacity: 0.9 }}>
                                                    Amount Owed
                                                </Typography>
                                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                                    ${billSplit.user_totals[userId].toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {renderEditModal()}
            {renderImageModal()}
        </Paper>
    );
};

export default BillSplitter;