import { Action, configureStore, ThunkAction, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import counterReducer from '../features/counter/counterSlice';
import { notificationReducer } from './slices/notificationSlice';
import themeReducer from './slices/theme';
import { userReducer } from './slices/userSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['theme', 'user'] // Only persist theme and user state
};

const rootReducer = combineReducers({
  counter: counterReducer,
  theme: themeReducer,
  notification: notificationReducer,
  user: userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    })
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
