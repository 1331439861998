import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Divider from '@mui/material/Divider';
import AdUnit from '../AdUnit/AdUnit';
import StarIcon from '@mui/icons-material/Star';
import { Box, Container, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import ReactGA from 'react-ga4';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { motion } from 'framer-motion';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import { useUser } from '../../stores/actions/StoreActions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


interface CartineseProps { }

const MAX_CARTI_INPUT_LENGTH = 50;
const MAX_REQUESTS_PER_PERIOD_GUEST = 3;
const MAX_REQUESTS_PER_PERIOD_USER = 10;
const REQUEST_PERIOD_HOURS = 24;


const ProductShowcase = () => {
    const navigate = useNavigate();

    const features = [
        {
            icon: <AutoAwesomeIcon fontSize="large" />,
            title: 'Smart Translation',
            description: 'Sophisticated translation system that captures Carti\'s unique style and transforms your text with authentic Cartinese elements'
        },
        {
            icon: <SpeedIcon fontSize="large" />,
            title: 'Instant Results',
            description: 'Get your translations in seconds. No waiting, no delays - just pure Cartinese vibes whenever you need them'
        },
        {
            icon: <SecurityIcon fontSize="large" />,
            title: 'Free to Try',
            description: 'Start translating right away with our free tier. Upgrade anytime to unlock unlimited access and premium features'
        }
    ];

    const examples = [
        {
            original: "I'm at the top of the buildin",
            translated: `hey . I'm- < 3 at! 🖤 the! top 🦋 ^ 🦋* !+  of++ the 🧛🏿‍♂️ ** !++buildin 🧛🏿‍♂️ ^ 🦋* !+ !!!!`
        },
        {
            original: `I'd rather get caught with it than without it`,
            translated: `🖤🖤🖤 ^ 🦋*  !+ I\'d 🧛🏿‍♂️ rather^ :) get :) caught 💔 with it! 🦋 ++ than 😦 without 💕 ^ 🦋*  !+  it - kING vAMP`
        },
        {
            original: "she singin' my songs, she wanna die lit",
            translated: `she 🧛🏿‍♂️ & * singin' 😦 :) my! & * songs, *^ ! she* & * wanna <3 ^ 🦋*  !+  die. *+ _ slatt lit. 🧛🏿‍♂️`
        }
    ];

    return (
        <Container maxWidth="md">
            {/* Hero Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 }, textAlign: 'center' }}>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <Typography
                        variant="h1"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            mb: 4,
                            fontSize: { xs: '2.5rem', md: '3.75rem' }
                        }}
                    >
                        Playboi Carti Translator
                    </Typography>
                    <Typography
                        variant="h2"
                        color="text.secondary"
                        sx={{
                            mb: 6,
                            px: { xs: 2, md: 8 },
                            fontSize: { xs: '1.2rem', md: '1.5rem' }
                        }}
                    >
                        Transform your everyday text into authentic Cartinese with the world's most advanced Playboi Carti text translator
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate('/login', { state: { returnTo: '/cartinese' } })}
                        sx={{
                            py: 2,
                            px: 6,
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            borderRadius: '30px',
                            textTransform: 'none',
                            boxShadow: '0 4px 14px 0 rgba(0,0,0,0.25)',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 6px 20px 0 rgba(0,0,0,0.3)',
                            },
                            transition: 'all 0.2s ease-in-out'
                        }}
                    >
                        Start Translating Now
                    </Button>
                </motion.div>
            </Box>

            {/* Features Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 } }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        mb: { xs: 4, md: 6 },
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Why Choose Our Cartinese Translator
                </Typography>
                <Grid container spacing={4}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: index * 0.2 }}
                                style={{ height: '100%' }}
                            >
                                <Card sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: 3
                                }}>
                                    <CardContent sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: { xs: 'auto', md: '340px' }
                                    }}>
                                        <Box sx={{
                                            mb: 3,
                                            color: 'primary.main',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            {feature.icon}
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            sx={{
                                                mb: 2,
                                                fontWeight: 500,
                                                height: '48px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {feature.title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Social Proof Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 }, textAlign: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        mb: 4,
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Join the Vamp Movement
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
                    So many people are already speaking Cartinese. Don't get left behind.
                </Typography>
            </Box>

            {/* Examples Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 } }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        mb: { xs: 4, md: 6 },
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    See the Cartinese Translator in Action
                </Typography>
                {examples.map((example, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: index * 0.3 }}
                    >
                        <Card sx={{
                            mb: 4,
                            boxShadow: 3,
                            '&:hover': {
                                boxShadow: 6,
                                transform: 'translateY(-4px)',
                            },
                            transition: 'all 0.2s ease-in-out'
                        }}>
                            <CardContent sx={{ p: { xs: 2, md: 4 } }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            color="text.secondary"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            Original
                                        </Typography>
                                        <Typography variant="body1">
                                            {example.original}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            color="primary"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            Cartinese
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontStyle: 'italic',
                                                fontFamily: 'monospace'
                                            }}
                                        >
                                            {example.translated}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </motion.div>
                ))}
            </Box>

            {/* FAQ Section for SEO */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 } }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        mb: { xs: 4, md: 6 },
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Frequently Asked Questions
                </Typography>
                <Typography variant="body1" paragraph>
                    Learn how to use the most advanced Playboi Carti text translator and text generator available online. Our AI-powered Cartinese translator helps you convert regular English text into authentic Cartinese style.
                </Typography>

                {[
                    {
                        question: 'What is a Cartinese translator?',
                        answer: 'A Cartinese translator (also known as a Carti translator) is a specialized tool that converts normal English text into Playboi Carti\'s unique writing style, complete with emojis, special characters, and his signature patterns.'
                    },
                    {
                        question: 'How does the Playboi Carti text generator work?',
                        answer: 'Our Playboi Carti text generator analyzes and applies Carti\'s writing style to your text. It adds characteristic elements like vampire emojis (🧛‍♂️), butterflies (🦋), hearts (💕), and his signature punctuation patterns to create authentic-looking Cartinese text.'
                    },
                    {
                        question: 'What\'s the difference between Cartinese and regular English?',
                        answer: 'Cartinese is a unique writing style that transforms English to Cartinese by incorporating special characters, emojis, and stylistic elements popularized by Playboi Carti. It includes features like repeated punctuation, strategic capitalization, and iconic symbols that represent Carti\'s aesthetic.'
                    },
                    {
                        question: 'Is this the best Carti language translator?',
                        answer: 'Yes! Our Cartinese generator is the most advanced and accurate Playboi Carti translator available online. It\'s trained on extensive data to ensure authentic Carti language translations that capture his unique style and energy.'
                    },
                    {
                        question: 'How can I generate Carti text?',
                        answer: 'Simply type your regular text into the input field, adjust any translation options to your preference, and click "Translate". Our Carti text generator will instantly convert your message into authentic Cartinese style.'
                    }
                ].map((faq, index) => (
                    <Accordion
                        key={index}
                        sx={{
                            mb: 1,
                            backgroundColor: 'background.paper',
                            boxShadow: 'none',
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: '0 0 8px 0',
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                '&.Mui-expanded': {
                                    minHeight: '48px',
                                }
                            }}
                        >
                            <Typography
                                component="h3"
                                variant="h6"
                                sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    color: 'text.primary'
                                }}
                            >
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'text.secondary',
                                    lineHeight: 1.6
                                }}
                            >
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>

            {/* CTA Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 }, textAlign: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        mb: 4,
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Ready to Speak Cartinese?
                </Typography>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/login', { state: { returnTo: '/cartinese' } })}
                    sx={{
                        py: 2,
                        px: 6,
                        fontSize: { xs: '1rem', md: '1.2rem' },
                        borderRadius: '30px',
                        textTransform: 'none',
                        boxShadow: '0 4px 14px 0 rgba(0,0,0,0.25)',
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 6px 20px 0 rgba(0,0,0,0.3)',
                        },
                        transition: 'all 0.2s ease-in-out'
                    }}
                >
                    Start Translating Now
                </Button>
            </Box>
        </Container>
    );
};

const Cartinese: React.FC<CartineseProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const { getUser } = useUser();
    const [searchParams] = useSearchParams();
    const isPremium = user?.carti_premium?.premium_status;
    const legacyPremium = searchParams.get('earlybih') === 'true';
    const [defaultConfig, setDefaultConfig] = useState<{ [key: string]: any } | null>(null);
    const [config, setConfig] = useState<{ [key: string]: any } | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [input, setInput] = useState('');
    const [cartinese, setCartinese] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);


    useEffect(() => {
        document.title = 'Playboi Carti Translator';
        if (legacyPremium && !isPremium) {
            addNotificationError('Please log in to regain access to premium features. Contact support if you run into any issues.')
            navigate('/login');
        };
        getCartifyConfig();
    }, []);

    const [requestLimitReached, setRequestLimitReached] = useState(false);
    const [resetTime, setResetTime] = useState<Date | null>(null);

    const canMakeRequest = () => {
        return true;
        if (isPremium) {
            setRequestLimitReached(false);
            setResetTime(null);
            return true;
        }
        const now = new Date().getTime();
        const periodAgo = now - REQUEST_PERIOD_HOURS * 3600000; // Convert hours to milliseconds
        const requests = JSON.parse(localStorage.getItem('cartineseRequests') || '[]');
        const recentRequests = requests.filter((time: number) => time > periodAgo);

        const maxRequests = isPremium ? Infinity : (user?.username ? MAX_REQUESTS_PER_PERIOD_USER : MAX_REQUESTS_PER_PERIOD_GUEST);

        if (recentRequests.length >= maxRequests) {
            if (!isPremium) {
                const oldestRequest = Math.min(...recentRequests);
                const resetTime = new Date(oldestRequest + REQUEST_PERIOD_HOURS * 3600000);
                addNotificationError(`Request limit reached. Upgrade for unlimited requests.`);
                setResetTime(resetTime);
                setRequestLimitReached(true);
            }
            return false;
        } else {
            setRequestLimitReached(false);
            setResetTime(null);
        };

        recentRequests.push(now);
        localStorage.setItem('cartineseRequests', JSON.stringify(recentRequests));
        return true;
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        setAnchorEl(event.currentTarget);
        setOpenPopoverId(id);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenPopoverId(null);
    };

    const LiveTimer = ({ resetTime }: { resetTime: Date | null }) => {
        const [timeLeft, setTimeLeft] = useState(resetTime ? Math.floor((resetTime.getTime() - new Date().getTime()) / 1000) : 0);

        useEffect(() => {
            if (resetTime) {
                const timer = setInterval(() => {
                    setTimeLeft((prevTime) => prevTime > 0 ? prevTime - 1 : 0);
                }, 1000);

                return () => clearInterval(timer);
            }
        }, [resetTime]);

        const formatTime = (seconds: number) => {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        };

        return (
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
                Next free translation in: <span style={{ fontFamily: 'monospace', fontSize: '1.2em' }}>{formatTime(timeLeft)}</span>
            </Typography>
        );
    };

    const cartify = () => {
        if (!canMakeRequest()) return;

        if (input.length === 0) {
            addNotificationError('Please enter some text to translate');
            return;
        }

        setIsLoading(true);
        setCartinese('');
        const method = isPremium ? 'cartifyPremium' : 'cartify';
        Client_.client_()[method](input, config).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                // Check for rate limit error message
                const waitTimeMatch = err?.match(/Please wait (\d+) hours and (\d+) minutes/);
                if (waitTimeMatch) {
                    const hours = parseInt(waitTimeMatch[1]);
                    const minutes = parseInt(waitTimeMatch[2]);
                    const resetTime = new Date();
                    resetTime.setHours(resetTime.getHours() + hours);
                    resetTime.setMinutes(resetTime.getMinutes() + minutes);

                    setResetTime(resetTime);
                    setRequestLimitReached(true);
                    addNotificationError(err);
                } else if (err?.toLowerCase().includes('log in')) {
                    addNotification('Please log in to continue translating');
                    navigate('/login', {
                        state: {
                            returnTo: '/cartinese',
                            message: 'Log in to continue translating',
                            forceRedirect: true
                        }
                    });
                } else {
                    addNotificationError(err);
                }
                return;
            } else {
                setCartinese(res?.result?.cartinese);
            }
        });
    };

    const getCartifyConfig = () => {
        Client_.client_().getCartifyConfig().then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setDefaultConfig(res?.result?.config);
            }
        });
    }

    const handleCopyText = () => {
        navigator.clipboard.writeText(cartinese);
        addNotificationSuccess('Copied to clipboard');
    };

    const handlePremiumClick = () => {
        addNotification('Join Vamp Nation to use this feature!');
        ReactGA.event('view_item', {
            items: [{
                id: 'cartinese_premium',
                name: 'Cartinese Premium',
                category: 'Premium',
                quantity: 1,
                price: 1.00
            }]
        });
        navigate('/cartinese/purchase');
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInput = isPremium ? event.target.value : event.target.value.slice(0, MAX_CARTI_INPUT_LENGTH);
        setInput(newInput);
    };

    if (!user?.username) {
        return <ProductShowcase />;
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Playboi Carti Translator
                </Typography>
                {requestLimitReached && (
                    <Box sx={{
                        width: '100%',
                        mb: 4,
                        p: 4,
                        borderRadius: 4,
                        backgroundColor: 'error.main',
                        color: 'error.contrastText',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                        position: 'relative',
                        overflow: 'hidden',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 15px 30px rgba(0,0,0,0.3)',
                        },
                    }}>
                        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase', letterSpacing: 1 }}>
                            🚫 Limit Reached!
                        </Typography>
                        <LiveTimer resetTime={resetTime} />
                        <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', fontWeight: 500 }}>
                            Don't let limits hold you back. Upgrade now to unlock:
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 3, width: '100%' }}>
                            {['⚡ Instant Translations', '📝 Unlimited Characters', '⚙️ Custom Settings', '🔄 Unlimited Translations', ].map((feature, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <CheckCircleIcon sx={{ mr: 1, color: 'success.light' }} />
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        {feature}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/cartinese/purchase')}
                            startIcon={<StarIcon />}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.2em',
                                py: 2,
                                px: 4,
                                mt: 2,
                                backgroundColor: 'white',
                                color: 'error.main',
                                '&:hover': {
                                    backgroundColor: 'error.light',
                                    color: 'white',
                                },
                                transition: 'all 0.3s ease-in-out',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                borderRadius: '30px',
                                width: '100%',
                                maxWidth: '300px',
                            }}
                        >
                            Upgrade to Premium Now!
                        </Button>
                        <Typography variant="caption" sx={{ mt: 2, textAlign: 'center', fontStyle: 'italic' }}>
                            Join a bunch of satisfied Carti fans!
                        </Typography>
                        <Box sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            width: 80,
                            height: 80,
                            backgroundColor: 'error.dark',
                            transform: 'rotate(45deg)',
                        }} />
                    </Box>
                )}
                <Box sx={{ width: '100%', position: 'relative' }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        value={input}
                        multiline
                        rows={4}
                        onChange={handleInputChange}
                        placeholder={`** !++ Enter the++**💕 text* ! +:)💔 you. .$ wantxo !😦 translated++ ++ 🖤#love *`}
                        sx={{ mb: 1 }}
                        inputProps={{
                            maxLength: isPremium ? undefined : MAX_CARTI_INPUT_LENGTH
                        }}
                        helperText={
                            !isPremium && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: input.length >= MAX_CARTI_INPUT_LENGTH * 0.8 ? 'error.main' : 'text.secondary'
                                        }}
                                    >
                                        {MAX_CARTI_INPUT_LENGTH - input.length} characters🦋 remaining🖤 * ok  !+
                                    </Typography>
                                </Box>
                            )
                        }
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mb: 2,
                            backgroundColor: 'background.paper',
                            borderRadius: '12px',
                            padding: 2,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                            Translation Options
                        </Typography>
                        {[
                            { label: '🧛‍♂️ Vamp Carti', name: 'vamp' },
                            { label: '⚡ Instant Translations', name: 'instant', checked: isPremium },
                            { label: '📝 Unlimited Characters', name: 'unlimited', checked: isPremium },
                            { label: '🔄 Unlimited Translations', name: 'limit', checked: isPremium },
                        ].map((option) => (
                            <Box
                                key={option.name}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mb: 1,
                                }}
                            >
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {option.label}
                                </Typography>
                                <Switch
                                    size="small"
                                    checked={option.checked || false}
                                    onChange={() => {
                                        if (option.name === 'vamp' && isPremium) {
                                            addNotification('Coming soon!');
                                        } else {
                                            handlePremiumClick();
                                        }
                                    }}
                                    name={option.name}
                                    color="primary"
                                />
                            </Box>
                        ))}
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Button
                                    onClick={() => setOpenModal(true)}
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        textTransform: 'none'
                                    }}
                                >
                                    Show Advanced Options
                                    <ExpandMoreIcon />
                                </Button>
                            </Box>
                            <Modal
                                open={openModal}
                                onClose={() => setOpenModal(false)}
                                closeAfterTransition
                                slotProps={{
                                    backdrop: {
                                        timeout: 500,
                                    },
                                }}
                            >
                                <Fade in={openModal}>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: { xs: '90%', sm: 400 },  // Responsive width
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 3,  // Reduced padding for mobile
                                        maxHeight: '90vh',  // Increased max height
                                        overflowY: 'auto',
                                        borderRadius: 2,
                                    }}>
                                        {defaultConfig && defaultConfig.map((option: any) => (
                                            <Box
                                                key={option.name}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    backgroundColor: 'background.paper',
                                                    borderRadius: '8px',
                                                    padding: '12px',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                    mb: 2,
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                                                    <Typography variant='body2' sx={{ fontWeight: 500 }}>
                                                        {option.display_name}
                                                    </Typography>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="info"
                                                        onClick={(event) => handlePopoverOpen(event, option.name)}
                                                    >
                                                        <InfoOutlinedIcon fontSize="small" />
                                                    </IconButton>
                                                    <Popover
                                                        id={option.name}
                                                        open={openPopoverId === option.name}
                                                        anchorEl={anchorEl}
                                                        onClose={handlePopoverClose}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>{option.help}</Typography>
                                                    </Popover>
                                                </Box>
                                                {typeof option.default === 'boolean' ? (
                                                    <Switch
                                                        checked={option.value ?? option.default}
                                                        onChange={(event) => {
                                                            if (isPremium) {
                                                                setConfig(prevConfig => ({
                                                                    ...prevConfig,
                                                                    [option.name]: event.target.checked
                                                                }));
                                                            } else {
                                                                handlePremiumClick();
                                                            }
                                                        }}
                                                        color='primary'
                                                    />
                                                ) : (
                                                    <Slider
                                                        value={config?.[option.name] ?? option.default}
                                                        onChange={(_, newValue) => {
                                                            if (isPremium) {
                                                                setConfig(prevConfig => ({
                                                                    ...prevConfig,
                                                                    [option.name]: newValue as number
                                                                }));
                                                            } else {
                                                                handlePremiumClick();
                                                            }
                                                        }}
                                                        min={0}
                                                        max={100}
                                                        step={null}
                                                        marks={[
                                                            { value: 0, label: 'Off' },
                                                            { value: 33, label: 'Rare' },
                                                            { value: 66, label: 'Often' },
                                                            { value: 100, label: 'Max' }
                                                        ]}
                                                        valueLabelDisplay='off'
                                                        sx={{
                                                            width: '100%',
                                                            '& .MuiSlider-thumb': {
                                                                width: 16,
                                                                height: 16,
                                                            },
                                                            '& .MuiSlider-rail': {
                                                                opacity: 0.8,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        ))}

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                            <Button
                                                onClick={() => {
                                                    setConfig({});
                                                    setOpenModal(false);
                                                }}
                                                variant="outlined"
                                                sx={{ textTransform: 'none' }}
                                            >
                                                Reset to Default
                                            </Button>
                                            <Button
                                                onClick={() => setOpenModal(false)}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                        </Box>
                                    </Box>
                                </Fade>
                            </Modal>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {isLoading ? (
                        <CircularProgress sx={{ mb: 2 }} />
                    ) : (
                        <Box sx={{ width: '100%', mb: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, width: '100%' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => cartify()}
                                    fullWidth
                                    sx={{
                                        borderRadius: '28px',
                                        textTransform: 'none',
                                        py: 1.5,
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
                                    }}
                                >
                                    Translate
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Divider sx={{ width: '100%', mb: 2 }} />

                {cartinese && (
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            label="Cartinese"
                            placeholder="Empty"
                            value={cartinese}
                            onChange={(event) => setCartinese(event.target.value)}
                            InputProps={{
                                readOnly: true,
                                style: { color: 'white' }
                            }}
                            sx={{
                                mb: 2,
                                backgroundColor: 'inherit',
                                '& .MuiInputBase-root': {
                                    height: 'auto',
                                    overflow: 'auto'
                                }
                            }}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<FileCopyIcon />}
                            onClick={handleCopyText}
                        >
                            Copy
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default Cartinese;