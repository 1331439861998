import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Link from '../../components/Link';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { styled } from '@mui/material/styles';

interface Product {
    primary: string;
    secondary: string;
    to: string;
    twitterLink?: string;
    priorty?: number;
}

const StyledCardActions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    gap: '8px', // Space between Explore button and Demo text
}));

const ExploreButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#4A90E2',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '1rem',
    padding: '10px 0',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(74, 144, 226, 0.3)',
    transition: 'background-color 0.3s, box-shadow 0.3s', // Add transitions
    '&:hover': {
        backgroundColor: '#3A7BC8',
        boxShadow: '0 6px 8px rgba(74, 144, 226, 0.5)',
    },
    // Responsive width for larger screens
    [theme.breakpoints.up('sm')]: {
        flex: '0 0 75%', // Explore button takes 75% on medium and larger screens
    },
}));

const DemoText = styled(Typography)(({ theme }) => ({
    color: '#1DA1F2',
    fontWeight: 'bold',
    cursor: 'pointer', // Indicate it's clickable
    display: 'flex',
    alignItems: 'center',
    transition: 'color 0.3s', // Add transitions
    '&:hover': {
        color: '#3A7BC8', // Change color on hover
    },
    // Responsive flex for smaller screens
    [theme.breakpoints.down('xs')]: {
        flex: '1', // Demo text takes available space on small screens
    },
}));

function ProductGrid({ products }: { products: Product[] }) {
    return (
        <Grid container spacing={4}>
            {products.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#111111',
                            borderRadius: '12px',
                            boxShadow: '0 8px 16px rgba(255, 255, 255, 0.1)',
                            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 12px 20px rgba(255, 255, 255, 0.15)',
                            },
                        }}
                    >
                        <CardContent sx={{ flexGrow: 1, padding: '24px' }}>
                            <Typography variant='h5' component='div' gutterBottom sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                {product.primary}
                            </Typography>
                            <Typography variant='body2' sx={{ color: '#cccccc', marginBottom: '16px' }}>
                                {product.secondary}
                            </Typography>
                        </CardContent>
                        <StyledCardActions>
                            <Link to={product.to} style={{ textDecoration: 'none', flex: product.twitterLink ? '0 0 75%' : '1' }}>
                                <ExploreButton variant='contained' fullWidth>
                                    Explore
                                </ExploreButton>
                            </Link>
                            {product.twitterLink && (
                                <DemoText
                                    onClick={() => window.open(product.twitterLink, '_blank')}
                                    variant='body2'
                                >
                                    <PlayCircleOutlineIcon sx={{ mr: 0.5 }} /> {/* Demo icon */}
                                    Demo
                                </DemoText>
                            )}
                        </StyledCardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default ProductGrid;