import { useState } from 'react';
import { Fab, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';


const FeedbackButton = () => {
    const user = useSelector((state: any) => state.user);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState(user?.email);
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        if (!feedback) {
            addNotificationError('Feedback is required');
            return;
        }
        let url = window.location.href;
        let feedbackWithUrl = feedback + '\n\nURL: ' + url;

        setIsSubmitting(true);
        Client_.client_().leaveFeedback(feedbackWithUrl, email).then(({ err, res }) => {
            setIsSubmitting(false);
            if (err || !res?.success) {
                addNotificationError(err || 'There was an error submitting your feedback. Please try again.');
                return;
            } else {
                addNotificationSuccess('Thank you for your feedback!');
                handleClose();
                setEmail('');
                setFeedback('');
            }
        });
    };

    return (
        <>
            <Tooltip title="Provide feedback or get support" arrow>
                <Fab
                    color="primary"
                    aria-label="feedback"
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                    }}
                    onClick={handleOpen}
                >
                    <FeedbackIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Leave Feedback</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address (optional)"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="feedback"
                        label="Message"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FeedbackButton;