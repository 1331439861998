import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import constants from '../../constants/Constants';

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Privacy Policy for app.saarim.me
                </Typography>

                <Typography variant="body1" paragraph>
                    Last updated: September 24, 2024
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to app.saarim.me. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We collect basic information such as your name and email address when you create an account. We also collect usage data and analytics as you interact with our website and tools.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    3. How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use your information to provide and improve our services, process payments, send occasional marketing emails, and enhance user experience.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    4. Cookies
                </Typography>
                <Typography variant="body1" paragraph>
                    We use cookies to enhance user experience, including persisting sessions and preventing frequent logouts.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    5. Third-Party Services
                </Typography>
                <Typography variant="body1" paragraph>
                    We use Stripe for payment processing and may share necessary data with them. In some cases, we may share data with Spotify. We also use Google Analytics to collect usage data.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    6. Data Retention and Deletion
                </Typography>
                <Typography variant="body1" paragraph>
                    Deleted content is removed immediately and not stored. Users can contact support to request access to or deletion of their data.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We implement standard security practices to protect your data. However, no method of transmission over the Internet is 100% secure.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    8. AI-Generated Content
                </Typography>
                <Typography variant="body1" paragraph>
                    Certain parts of our product involve AI-generated content. This content may not be completely accurate and should be taken with a grain of salt.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    9. External Links
                </Typography>
                <Typography variant="body1" paragraph>
                    Our website may contain links to external sites that are not operated by us. We have no control over the content and practices of these sites.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    10. Changes to Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    Any changes to our Terms of Service will be reflected on this page.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    11. Termination of Service
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to terminate anyone's access to our website at any time, particularly in cases of gross misuse.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    12. Contact Information
                </Typography>
                <Typography variant="body1" paragraph>
                    For any questions, concerns, or disputes regarding this Privacy Policy, please contact our support team at {constants.supportEmail}.
                </Typography>

                <Typography variant="body1" paragraph>
                    By using app.saarim.me, you agree to the collection and use of information in accordance with this policy.
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;