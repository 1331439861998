import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import Constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';


interface PasswordResetProps { }


const PasswordReset: React.FC<PasswordResetProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [email, setEmail] = useState(user.email ?? '');
    const [emailSent, setEmailSent] = useState(false);
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const hashString = window.location.hash.substring(1); // remove the # symbol
    const hashParams = new URLSearchParams(hashString);
    const type = hashParams.get('type');
    const access_token = hashParams.get('access_token');
    const refresh_token = hashParams.get('refresh_token');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sendForgotPasswordEmail = async () => {
        setLoading(true);
        try {
            const { err, res } = await Client_.client_().forgotPassword(email);
            if (err || !res?.success) {
                addNotificationError(err || 'Failed to send reset email');
            } else {
                setEmailSent(true);
            }
        } finally {
            setLoading(false);
        }
    };

    const resetPassword = async () => {
        if (!access_token || !refresh_token) {
            addNotificationError('Invalid reset link');
            return;
        }

        setLoading(true);
        try {
            const { err, res } = await Client_.client_().resetPassword(access_token, refresh_token, password);
            if (err || !res?.success) {
                addNotificationError(err || 'Failed to reset password');
            } else {
                addNotificationSuccess('Password reset successfully');
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };


    const initReset = () => {
        return emailSent ? (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                padding: theme.spacing(3)
            }}>
                <CheckCircleOutlineIcon
                    sx={{
                        marginTop: 2,
                        fontSize: 64,
                        color: theme.palette.success.main
                    }}
                />
                <h2 style={{ marginTop: 20, color: theme.palette.text.primary }}>
                    Email sent!
                </h2>
                <p style={{ color: theme.palette.text.secondary }}>
                    Check your email and open the link we sent to continue
                </p>
            </Box>
        ) : (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                padding: theme.spacing(3)
            }}>
                <h1 style={{ color: theme.palette.text.primary }}>
                    Password Reset
                </h1>
                <p style={{
                    color: theme.palette.text.secondary,
                    marginBottom: theme.spacing(3)
                }}>
                    Enter your email and we'll send you a link to reset your password
                </p>
                <TextField
                    sx={{
                        width: isMobile ? '100%' : '70%',
                        marginBottom: theme.spacing(3)
                    }}
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                {loading ? (
                    <CircularProgress sx={{ marginTop: theme.spacing(2) }} />
                ) : (
                    <Button
                        sx={{
                            width: isMobile ? '100%' : '200px',
                            marginTop: theme.spacing(2),
                            height: '48px'
                        }}
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={() => sendForgotPasswordEmail()}
                    >
                        Send link to email
                    </Button>
                )}
            </Box>
        );
    };

    const acceptReset = () => {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                padding: theme.spacing(3)
            }}>
                <h1 style={{ color: theme.palette.text.primary }}>
                    Reset Your Password
                </h1>
                <TextField
                    sx={{
                        width: isMobile ? '100%' : '70%',
                        marginTop: theme.spacing(3),
                        marginBottom: theme.spacing(2)
                    }}
                    id='outlined-basic'
                    label='New Password'
                    variant='outlined'
                    type='password'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    helperText={Constants.passwordRequirements}
                />
                {loading ? (
                    <CircularProgress sx={{ marginTop: theme.spacing(2) }} />
                ) : (
                    <Button
                        sx={{
                            width: isMobile ? '100%' : '200px',
                            marginTop: theme.spacing(2),
                            height: '48px'
                        }}
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={() => resetPassword()}
                    >
                        Reset password
                    </Button>
                )}
            </Box>
        );
    };

    return (
        <Container maxWidth='sm' sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(2)
        }}>
            <Paper elevation={3} sx={{
                width: '100%',
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.paper,
                overflow: 'hidden'
            }}>
                {type === 'recovery' && access_token ? acceptReset() : initReset()}
            </Paper>
        </Container>
    );
};

export default PasswordReset;