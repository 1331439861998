import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import ReactMarkdown from 'react-markdown';
interface ChatMessage {
    role: string;
    text: string;
    timestamp: string;
}

const starterQuestions = [
    'What is your most impressive accomplishment?',
    'What makes you different from other engineers?',
    'Can you tell me a bit about yourself?',
    'Can you summarize your career for me?',
    'Why are you uniquely qualified to join my company?',
]

const SaarimChat: React.FC = () => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = `Saarim Chat`;
    }, []);

    const sendChatMessage = async (query: any) => {
        setIsLoading(true);

        if (query.trim() === '') {
            setIsLoading(false);
            addNotificationError('Please enter a message');
            return;
        }

        const chatHistoryCopy = [...chatHistory];
        setMessage('');

        chatHistory.push({
            role: 'user',
            text: query,
            timestamp: new Date().toISOString(),
        })

        if (chatHistory.length === 2 && Object.keys(chatHistory[0]).length === 0) {
            chatHistory.shift();
        };

        Client_.client_().askSaarim(query, chatHistoryCopy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setChatHistory(res?.result?.chat_history)
            }
        });
    };

    const handleButtonClick = (text: any) => {
        setMessage(text);
        sendChatMessage(text);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'black', color: 'white' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: '20px', color: 'white', fontWeight: 'bold', mt: 2 }}>AI Chat with Saarim</Typography>

            <div style={{
                flex: 1,
                overflowY: 'auto',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {chatHistory && Object.keys(chatHistory[0]).length === 0 && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '20px'
                    }}>
                        <Typography variant="h6" style={{ marginBottom: '10px', color: 'white' }}>
                            Start the conversation with a question:
                        </Typography>
                        {starterQuestions.map((question, index) => (
                            <Button
                                onClick={() => handleButtonClick(question)}
                                variant="outlined"
                                key={index}
                                style={{ width: '100%', maxWidth: '500px'}}
                            >
                                {question}
                            </Button>
                        ))}
                    </div>
                )}

                {chatHistory && Object.keys(chatHistory[0]).length > 0 && chatHistory.map((chatMessage: any, index: number) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: chatMessage.role === 'user' ? 'flex-end' : 'flex-start',
                            mb: 2
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '70%',
                                backgroundColor: chatMessage.role === 'user' ? '#007AFF' : '#1C1C1E',
                                color: 'white',
                                borderRadius: '20px',
                                padding: '0px 12px',
                                boxShadow: '0px 2px 5px rgba(255,255,255,0.1)'
                            }}
                        >
                            <Typography variant="body1" sx={{ margin: 0 }}>
                                <ReactMarkdown>{chatMessage.text}</ReactMarkdown>
                            </Typography>
                        </Box>
                    </Box>
                ))}
                {isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mb: 2
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '70%',
                                backgroundColor: '#1C1C1E',
                                color: 'white',
                                borderRadius: '20px',
                                padding: '10px 15px',
                                boxShadow: '0px 2px 5px rgba(255,255,255,0.1)',
                                animation: 'pulse 1.5s infinite'
                            }}
                        >
                            <Typography variant="body1">
                                Thinking...
                            </Typography>
                        </Box>
                    </Box>
                )}
            </div>

            <div style={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                backgroundColor: '#1C1C1E',
                boxShadow: '0px -2px 10px rgba(255,255,255,0.1)'
            }}>
                <Tooltip title="Clear chat">
                    <IconButton onClick={() => setChatHistory([{}])} style={{ marginRight: '10px', color: 'white' }}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>

                <TextField
                    style={{
                        width: '70%',
                        marginRight: '10px',
                        backgroundColor: '#2C2C2E',
                        borderRadius: '20px',
                    }}
                    InputProps={{
                        style: {
                            color: 'white',
                            borderRadius: '20px',
                        }
                    }}
                    id="outlined-basic"
                    placeholder="Send a message..."
                    variant="outlined"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            sendChatMessage(message);
                        }
                    }}
                    multiline
                    maxRows={3}
                />

                <IconButton
                    style={{
                        backgroundColor: message.trim() ? '#007AFF' : '#2C2C2E',
                        borderRadius: '50%',
                        padding: '10px',
                    }}
                    onClick={() => sendChatMessage(message)}
                    disabled={!message.trim() || isLoading}
                >
                    {isLoading ? (
                        <CircularProgress size={24} style={{ color: 'white' }} />
                    ) : (
                        <SendIcon style={{ color: 'white' }} />
                    )}
                </IconButton>
            </div>
            <style>
                {`
                @keyframes pulse {
                    0% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                `}
            </style>
        </div>
    );
};

export default SaarimChat;
