import axios, { AxiosInstance } from 'axios';
import { List } from 'reselect/es/types';

let staticClient: any = null;
let staticPromises: any = null;

interface IClient_ {
    register: (username: string, email: string, password: string, first_name: string) => Promise<any>;
    login: (email: string, password: string) => Promise<any>;
    loginWithToken: (access_token: string, refresh_token: string) => Promise<any>;
    forgotPassword: (email: string) => Promise<any>;
    resetPassword: (token: string, refresh_token: string, new_password: string) => Promise<any>;
    verifyEmail: (token: string) => Promise<any>;
    createNewAndSimilarPlaylist: (url: string, limit: number) => Promise<any>;
    getRankedTweetsForUser: (username: string, include_text: boolean, include_images: boolean) => Promise<any>;
    getRankedTweetsForUsers: (usernames: Array<string>, include_text: boolean, include_images: boolean, lookback: string, selected: string, upper_bound: string, lower_bound: string) => Promise<any>;
    generatePrompt: (assignment: string) => Promise<any>;
    improvePrompt: (prompt: string, assignment: string) => Promise<any>;
    queryTwitterUserChat: (username: string, query: string, chat_history: List) => Promise<any>;
    getItemsFromReceipt: (img: any) => Promise<any>;
    splitBill: (items: Array<any>, users: any, ids: any, custom_total: number | null) => Promise<any>;
    getCrimeData: (lookback_days: number, description_substring: string, latitude: number, longitude: number) => Promise<any>;
    askSaarim: (query: string, chat_history: List) => Promise<any>;
    initSpotifyOAuth: () => Promise<any>;
    setSpotifyToken: (code: string) => Promise<any>;
    validateSpotifyAuth: () => Promise<any>;
    findArtistEvents: (playlist_url: string, state: string, city: string, sort_by: string) => Promise<any>;
    createSetPlaylist: (tracklist: string, playlist_name: string) => Promise<any>;
    findDuplicates(playlist_url: string): Promise<any>;
    countArtistTracks(playlist_url: string): Promise<any>;
    removeDuplicates(playlist_id: string, tracks: Array<any>): Promise<any>;
    createCartineseCheckoutSession: (session_type: string) => Promise<any>;
    convertLatexToPdf: (latex: string) => Promise<any>;
    rewriteResumeSection: (resume_part: string, user_request: string) => Promise<any>;
    leaveFeedback: (feedback: string, email: string) => Promise<any>;
    logout: () => Promise<any>;
    getUser: () => Promise<any>;
    activateCartiSubscription: (session_id: string) => Promise<any>;
    getCartifyConfig: () => Promise<any>;
    cartify(input: string, config: any): Promise<any>;
    cartifyPremium: (input: string, config: any) => Promise<any>;
    createApiKey: () => Promise<any>;
    getCartiPriceInfo: () => Promise<any>;
    cancelSubscription: () => Promise<any>;
    createFilteredArtistPlaylist: (playlist_url: string, artists: string, new_playlist_name: string) => Promise<any>;
}

class Client_ implements IClient_ {

    // TODO: add error handling
    static client_: () => IClient_ = () => {
        if (!staticClient) {
            staticClient = new Client_();
        }
        return staticClient;
    };

    static readonly baseUrl: string = process.env.NODE_ENV === 'production'
        ? 'https://api.saarim.me'
        : '';

    static axiosInstance = axios.create({
        withCredentials: true
    });

    static callApi = async (method?: string, args?: any) => {
        let url = `${Client_.baseUrl}/api/${method}`;

        let postBody: any = {
            method: 'POST',
            url: url,
            headers: {
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Headers': '*'
            },
            data: args,
            withCredentials: true
        };

        let err: any = null;
        let res = null;

        try {
            await Client_.axiosInstance(postBody)
                .then((response) => {
                    res = response?.data;
                    err = res?.error;
                    return { err, res };
                });
        } catch (error) {
            err = 'An unexpected error occured. Please try again later'
            console.error(error)
            return { err, res };
        }
        return { err, res };
    };

    static callApiWithFormData = async (method?: string, formData?: any) => {
        let url = `${Client_.baseUrl}/api/${method}`;
        let postBody: any = {
            method: 'POST',
            url: url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        };
        let err: any = null;
        let res = null;

        try {
            await axios(postBody)
                .then((response) => {
                    res = response?.data;
                    err = res?.error;
                    return { err, res };
                });
        } catch (error) {
            err = 'An unexpected error occured. Please try again later'
            console.error(error)
            return { err, res };
        }
        return { err, res };
    };

    static callApiAndReturnBlob = async (method?: string, args?: any) => {
        let url = `${Client_.baseUrl}/api/${method}`;

        let postBody: any = {
            method: 'POST',
            url: url,
            headers: {
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Headers': '*'
            },
            data: args,
            withCredentials: true,
            responseType: 'arraybuffer'
        };

        let err: any = null;
        let res = null;

        try {
            const response = await Client_.axiosInstance(postBody);
            const contentType = response.headers['content-type'];

            if (contentType === 'application/json') {
                const decoder = new TextDecoder('utf-8');
                const jsonString = decoder.decode(response.data);
                res = JSON.parse(jsonString);
                err = res?.error;
            } else if (contentType) {
                res = new Blob([response.data], { type: contentType });
                err = null;
            } else {
                console.error(`Response is not a ${contentType}`);
            }
        } catch (error) {
            err = 'An unexpected error occurred. Please try again later';
            console.error(error);
            return { err, res };
        }
        return { err, res };
    };

    async createApiKey() {
        let args: any = {};
        return Client_.callApi('createApiKey', args);
    }

    async cartifyPremium(input: string, config: any) {
        let args: any = {
            input,
            config,
        };
        return Client_.callApi('cartifyPremium', args);
    }

    async getUser() {
        let args: any = {};
        return Client_.callApi('getUser', args);
    }

    async activateCartiSubscription(session_id: string) {
        let args: any = {
            session_id
        };
        return Client_.callApi('activateCartiSubscription', args);
    }

    async logout() {
        let args: any = {};
        return Client_.callApi('logout', args);
    }

    async leaveFeedback(feedback: string, email: string) {
        let args: any = {
            feedback,
            email
        };
        return Client_.callApi('leaveFeedback', args);
    }

    async register(username: string, email: string, password: string, first_name: string) {
        let args: any = {
            username,
            email,
            password,
            first_name
        };
        return Client_.callApi('register', args);
    }

    async login(email: string, password: string) {
        let args: any = {
            email,
            password
        };
        return Client_.callApi('login', args);
    }

    async forgotPassword(email: string) {
        let args: any = {
            email
        };
        return Client_.callApi('forgotPassword', args);
    }

    async resetPassword(token: string, refresh_token: string, new_password: string) {
        let args: any = {
            token,
            refresh_token,
            new_password,
        };
        return Client_.callApi('resetPassword', args);
    }

    async verifyEmail(token: string) {
        let args: any = {
            token
        };
        return Client_.callApi('verifyEmail', args);
    }

    async createNewAndSimilarPlaylist(url: string, limit: number) {
        let args: any = {
            url,
            limit
        };
        return Client_.callApi('createNewAndSimilarPlaylist', args);
    }

    async getRankedTweetsForUser(username: string, include_text: boolean, include_images: boolean) {
        let args: any = {
            username,
            include_text,
            include_images
        };
        return Client_.callApi('getRankedTweetsForUser', args);
    }

    async getRankedTweetsForUsers(usernames: Array<string>, include_text: boolean, include_images: boolean, lookback: string, selected: string, upper_bound: string, lower_bound: string) {
        let args: any = {
            usernames,
            include_text,
            include_images,
            lookback,
            selected,
            upper_bound,
            lower_bound
        };
        return Client_.callApi('getRankedTweetsForUsers', args);
    }

    async generatePrompt(assignment: string) {
        let args: any = {
            assignment
        };
        return Client_.callApi('generatePrompt', args);
    }

    async improvePrompt(prompt: string, assignment: string) {
        let args: any = {
            prompt,
            assignment
        };
        return Client_.callApi('improvePrompt', args);
    }

    async queryTwitterUserChat(username: string, query: string, chat_history: List) {
        let args: any = {
            username,
            query,
            chat_history
        };
        return Client_.callApi('queryTwitterUserChat', args);
    }

    async getItemsFromReceipt(img: any) {
        // let args: any = {
        //     img
        // };
        return Client_.callApiWithFormData('getItemsFromReceipt', img);
    }

    async splitBill(items: Array<any>, users: any, ids: any, custom_total: number | null) {
        let args: any = {
            items,
            users,
            ids,
            custom_total
        };
        return Client_.callApi('splitBill', args);
    }

    async getCrimeData(lookback_days: number, description_substring: string, latitude: number, longitude: number) {
        let args: any = {
            lookback_days,
            description_substring,
            latitude,
            longitude
        };
        return Client_.callApi('getCrimeData', args);
    }

    async askSaarim(query: string, chat_history: List) {
        let args: any = {
            query,
            chat_history
        };
        return Client_.callApi('askSaarim', args);
    }

    async setSpotifyToken(code: string) {
        let args: any = {
            code
        };
        return Client_.callApi('setSpotifyToken', args);
    };

    async initSpotifyOAuth() {
        let args: any = {};
        return Client_.callApi('initSpotifyOAuth', args);
    };

    async validateSpotifyAuth() {
        let args: any = {};
        return Client_.callApi('validateSpotifyAuth', args);
    };

    async findArtistEvents(playlist_url: string, state: string, city: string, sort_by: string) {
        let args: any = {
            playlist_url,
            state,
            city,
            sort_by
        };
        return Client_.callApi('findArtistEvents', args);
    };

    async createSetPlaylist(tracklist: string, playlist_name: string) {
        let args: any = {
            tracklist,
            playlist_name
        };
        return Client_.callApi('createSetPlaylist', args);
    };

    async findDuplicates(playlist_url: string) {
        let args: any = {
            playlist_url,
        };
        return Client_.callApi('findDuplicates', args);
    };

    async removeDuplicates(playlist_id: string, tracks: any[]): Promise<any> {
        let args: any = {
            playlist_id,
            tracks,
        };
        return Client_.callApi('removeDuplicates', args);
    };

    async cartify(input: string, config: any): Promise<any> {
        let args: any = {
            input,
            premium: false,
            config,
        };
        return Client_.callApi('cartify', args);
    };

    async getCartifyConfig(): Promise<any> {
        let args: any = {};
        return Client_.callApi('getCartifyConfig', args);
    };

    async countArtistTracks(playlist_url: string): Promise<any> {
        let args: any = {
            playlist_url,
        };
        return Client_.callApi('countArtistTracks', args);
    };

    async createCartineseCheckoutSession(session_type: string) {
        let args: any = {
            session_type,
        };
        return Client_.callApi('createCartineseCheckoutSession', args);
    };

    async getCartiPriceInfo(): Promise<any> {
        let args: any = {};
        return Client_.callApi('getCartiPriceInfo', args);
    };

    async convertLatexToPdf(latex: string): Promise<any> {
        let args: any = {
            latex,
        };
        return Client_.callApiAndReturnBlob('convertLatexToPdf', args);
    };

    async rewriteResumeSection(resume_part: string, user_request: string): Promise<any> {
        let args: any = {
            resume_part,
            user_request,
        };
        return Client_.callApi('rewriteResumeSection', args);
    };

    async cancelSubscription() {
        let args: any = {};
        return Client_.callApi('cancelSubscription', args);
    }

    async createFilteredArtistPlaylist(playlist_url: string, artists: string, new_playlist_name: string) {
        let args: any = {
            playlist_url,
            artists,
            new_playlist_name,
        };
        return Client_.callApi('createFilteredArtistPlaylist', args);
    }

    async loginWithToken(access_token: string, refresh_token: string) {
        let args: any = {
            access_token,
            refresh_token,
        };
        return Client_.callApi('loginWithToken', args);
    }
}

export default Client_;
