import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  RouterProvider
} from "react-router-dom";
import Header from './components/Header/Header';
import Notification from './components/Notifications/Notification';
import './index.css';
import reportWebVitals from './reportWebVitals';
import router from './Router';
import { store } from './stores/store';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import ReactGA from 'react-ga4';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './stores/store';
const TRACKING_ID = 'G-4TYYK84XM4';
ReactGA.initialize(TRACKING_ID);

const container = document.getElementById('root')!;
const root = createRoot(container);

const prefersDarkMode = true;
const theme = createTheme({
  palette: {
    mode: prefersDarkMode ? 'dark' : 'light',
  },
})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Notification />
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
        <SpeedInsights />
        <Analytics />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
