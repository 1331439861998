import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification } from '../../stores/actions/StoreActions';
import utils from '../../utils';
import ReactMarkdown from 'react-markdown';

const TwitterChat: React.FC = () => {
    const [message, setMessage] = useState('');
    const [user, setUser] = useState('');
    const [chatHistory, setChatHistory] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = `Twitter Chat`;
    }, []);

    const sendChatMessage = async () => {
        setIsLoading(true);

        const messageCopy = message;
        const chatHistoryCopy = [...chatHistory];
        setMessage('');
        setSearchParams({
            username: user,
        });

        chatHistory.push({
            role: 'user',
            text: message,
            timestamp: new Date().toISOString(),
        })

        document.title = `@${user} | Twitter Chat`;

        if (chatHistory.length === 2 && Object.keys(chatHistory[0]).length === 0) {
            chatHistory.shift();
        };

        Client_.client_().queryTwitterUserChat(user, messageCopy, chatHistoryCopy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setChatHistory(res?.result?.chat_history)
            }
        });
    };

    const setTwitterUser = (username: any) => {
        setUser(utils.getTwitterUserFromUrl(username));
        setChatHistory([{}]);
    };


    useEffect(() => {
        let searchUsername = searchParams.get('username') || '';
        setUser(searchUsername);
    }, []);


    const getTwitterUserFromUrl = (url: string) => {
        if (url.indexOf('twitter.com') === -1) {
            return url;
        }
        let username = url.split('twitter.com/')[1].split('/')[0];
        return username;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'black', color: 'white' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: '20px', color: 'white', fontWeight: 'bold', mt: 2 }}>Twitter Chat</Typography>
            <Typography variant="body2" sx={{ textAlign: 'center', marginBottom: '20px', color: 'white' }}>{constants.twitterDisclaimer}</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <TextField
                    sx={{
                        width: '75%',
                        backgroundColor: '#2C2C2E',
                        borderRadius: '20px',
                    }}
                    InputProps={{
                        style: {
                            color: 'white',
                            borderRadius: '20px',
                        }
                    }}
                    id="outlined-basic"
                    label="Twitter Handle"
                    variant="outlined"
                    value={user}
                    onChange={(event) => setTwitterUser(event.target.value)}
                    helperText='Enter the Twitter handle or profile URL of the user you want to chat with. E.g. for @Joe you would enter "Joe"'
                    FormHelperTextProps={{
                        style: { color: 'white' }
                    }}
                />
            </Box>

            <div style={{
                flex: 1,
                overflowY: 'auto',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {chatHistory && Object.keys(chatHistory[0]).length > 0 && chatHistory.map((chatMessage: any, index: number) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: chatMessage.role === 'user' ? 'flex-end' : 'flex-start',
                            mb: 2
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '70%',
                                backgroundColor: chatMessage.role === 'user' ? '#007AFF' : '#1C1C1E',
                                color: 'white',
                                borderRadius: '20px',
                                padding: '0px 12px',
                                boxShadow: '0px 2px 5px rgba(255,255,255,0.1)'
                            }}
                        >
                            <Typography variant="body1" sx={{ margin: 0 }}>
                                <ReactMarkdown>{chatMessage.text}</ReactMarkdown>
                            </Typography>
                        </Box>
                    </Box>
                ))}
                {isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mb: 2
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '70%',
                                backgroundColor: '#1C1C1E',
                                color: 'white',
                                borderRadius: '20px',
                                padding: '10px 15px',
                                boxShadow: '0px 2px 5px rgba(255,255,255,0.1)',
                                animation: 'pulse 1.5s infinite'
                            }}
                        >
                            <Typography variant="body1">
                                Thinking...
                            </Typography>
                        </Box>
                    </Box>
                )}
            </div>

            <div style={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
                backgroundColor: '#1C1C1E',
                boxShadow: '0px -2px 10px rgba(255,255,255,0.1)'
            }}>
                <Tooltip title="Clear chat">
                    <IconButton onClick={() => setChatHistory([{}])} style={{ marginRight: '10px', color: 'white' }}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>

                <TextField
                    style={{
                        width: '70%',
                        marginRight: '10px',
                        backgroundColor: '#2C2C2E',
                        borderRadius: '20px',
                    }}
                    InputProps={{
                        style: {
                            color: 'white',
                            borderRadius: '20px',
                        }
                    }}
                    id="outlined-basic"
                    placeholder="Send a message..."
                    variant="outlined"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            sendChatMessage();
                        }
                    }}
                    multiline
                    maxRows={3}
                    disabled={user === ''}
                    helperText={user === '' ? 'Please enter a Twitter handle or profile URL first' : ''}
                    FormHelperTextProps={{
                        style: { color: 'red' }
                    }}
                />

                <IconButton
                    style={{
                        backgroundColor: message.trim() ? '#007AFF' : '#2C2C2E',
                        borderRadius: '50%',
                        padding: '10px',
                    }}
                    onClick={sendChatMessage}
                    disabled={!message.trim() || isLoading || user === ''}
                >
                    {isLoading ? (
                        <CircularProgress size={24} style={{ color: 'white' }} />
                    ) : (
                        <SendIcon style={{ color: 'white' }} />
                    )}
                </IconButton>
            </div>
            <style>
                {`
                @keyframes pulse {
                    0% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        opacity: 1;
                    }
                }
                `}
            </style>
        </div>
    );
};

export default TwitterChat;
