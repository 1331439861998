import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';

interface SpotifyProps { }


const exampleTracklist = `0:09 ID - ID
1:18 Chocolate Puma ft. Kris Kiss - Frenzy
4:34 ID - ID
7:32 Dombresky - Bubblin
9:48  Tchami - Born Again
12:25 DLMT & Paraleven - One Drink
12:30 Belcastro - Pressure
13:51 Öwnboss & Sevek - Move Your Body
14:39 John Summit - La Danza
17:53 DJ Snake & Tchami & Malaa & MERCER - Made In France
18:50 Drake ft. Future & Young Thug - Way 2 Sexy (Valentino Khan Remix)
20:53 Tchami & Frents - ID
23:53 Tchami & AC Slater ft. Kate Wild - Only One
26:30 Tchami - Siaw
28:00 ID - ID
30:15 DMNDS & Fallen Roses & Lujavo ft. Lunis - Calabria (Shift K3Y Edit)
33:30 Tchami ft. Gunna - Praise
36:50 Dog Blood vs. Rob Base & DJ E-Z Rock vs. Tchami & Marshall Jefferson - Turn Off The Light vs. It Takes Two vs. Move Your Body (Tchami Mashup)
38:27 DJ Snake & AlunaGeorge - You Know You Like It (Tchami Remix)
40:15 MARTEN HØRGER & BRANDON - Let's Try It
43:00 Tchami ft. Stacy Barthe - After Life (Habstrakt Remix)
43:31 Michael Sparks - Tell Me
46:30 Curbi & Mike Cervello - Deja Vu
47:28 Wax Motif & longstoryshort - On The Low
49:44 Flume & MAY-A - Say Nothing (Tchami
Remix)
52:00 Sonny Fodera & Biscits - Perfect
55:29 Tchami - Adieu vs. Adieu Pt. II (Tchami Outro Mashup)`

const examplePlaylistName = 'Tchami - Ultra Music Festival Miami 2022'

const SetPlaylist: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()

    const [playlistName, setPlaylistName] = useState("My Set Tracklist");
    const [tracklist, setTracklist] = useState("");
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [couldntFindSongs, setCouldntFindSongs] = useState<string[]>([]);

    useEffect(() => {
        document.title = 'Create Set Playlist';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const createSetPlaylist = (tracklist: string, playlistName: string) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        Client_.client_().createSetPlaylist(tracklist, playlistName).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPlaylistUrl(res?.result?.playlist_url);
                setCouldntFindSongs(res?.result?.couldnt_find_tracks);
            }
        });
    }


    const setExampleData = () => {
        setTracklist(exampleTracklist);
        setPlaylistName(examplePlaylistName);
        createSetPlaylist(exampleTracklist, examplePlaylistName);
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Create a Set Playlist
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Paste the tracklist of the set you want to create a Spotify playlist for
            </p>

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Playlist Name"
                variant="outlined"
                value={playlistName}
                onChange={(event) => setPlaylistName(event.target.value)}
            />

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Tracklist"
                variant="outlined"
                value={tracklist}
                multiline
                rows={10}
                onChange={(event) => setTracklist(event.target.value)}
            />

            <div style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px'
            }}>
                {isLoading ? (
                    <CircularProgress sx={{ color: '#1DB954' }} />
                ) : (
                    <>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => createSetPlaylist(tracklist, playlistName)}
                        >
                            Create Playlist
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={setExampleData}
                        >
                            Use Example
                        </Button>
                    </>
                )}
            </div>

            {generatedPlaylistUrl && (
                <div style={{
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <h2 style={{
                        color: '#1DB954',
                        marginBottom: '20px',
                        fontSize: '1.5rem'
                    }}>
                        Playlist Created
                    </h2>
                    <Link
                        to={generatedPlaylistUrl}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            backgroundColor: '#1DB954',
                            color: 'white',
                            borderRadius: '20px',
                            textDecoration: 'none',
                            marginBottom: '20px',
                            fontSize: '0.9rem'
                        }}
                    >
                        Open in Spotify
                    </Link>
                    <p style={{ color: '#b3b3b3', fontSize: '0.9rem', marginTop: '12px' }}>
                        Note: the playlist may not be 100% accurate
                    </p>
                </div>
            )}

            {couldntFindSongs?.length > 0 && (
                <div style={{
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <h2 style={{
                        color: '#b3b3b3',
                        marginBottom: '16px',
                        fontSize: '1.2rem'
                    }}>
                        Couldn't find these tracks on Spotify:
                    </h2>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                    }}>
                        {couldntFindSongs.map((track: string, index: number) => (
                            <div key={index} style={{
                                padding: '8px 12px',
                                backgroundColor: '#282828',
                                borderRadius: '4px',
                                color: '#b3b3b3',
                                fontSize: '0.9rem',
                                textAlign: 'left'
                            }}>
                                {track}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SetPlaylist;
